<template>
    <div>
      <div class="path-mobile">
        <p class="path-mobile">Партнерам</p>
      </div>
      <div class="partners_header"
        :class="{ map: currentTab === 'Контакты' }">
        <div class="assurance_head partners">
          <div class="path about"
            v-if="currentTab === 'Информация партнерам'">
            <a class="path blue" @click="openPage('/')"><p class="path">Главная</p></a>
            <p class="path blue selected"> | </p>
            <p class="path blue selected">Партнерам</p>
          </div>
          <div class="path about"
            v-if="currentTab === 'Контакты'">
            <a class="path blue" @click="openPage('/')"><p class="path">Главная</p></a>
            <p class="path blue"> | </p>
            <p class="path blue">Партнерам</p>
            <p class="path blue selected"> | </p>
            <p class="path blue selected">Контакты</p>
          </div>
          <div class="header_buttons">
            <button
              class="info_nav"
              :class="{ active: currentTab === tab }"
              v-for="(tab, index) in navigationTabs"
              :key="index"
              @click="(currentTab = tab)">
                {{ tab }}
            </button>
          </div>
          <div class="carousel_catalog show-sm">
            <carousel :items="1" :nav="false" :dots="false"
                :margin="30" :autoWidth="true" :autoHeight="true">
                <button
                    class="info_nav"
                    :class="{ active: currentTab === tab }"
                    v-for="(tab, index) in navigationTabs"
                    :key="index"
                    @click="(currentTab = tab)">
                      {{tab}}
                </button>
                <button class="button__visibility_hidden"></button>
                <button class="button__visibility_hidden"></button>
            </carousel>
          </div>
          <div class="header_content"
            v-show="currentTab === 'Информация партнерам'">
            <h2 class="catalog_info_titles white">Японские аккумуляторы<br>
              furukawa battery<br>эксклюзивно в Юником</h2>
            <p class="assurance_thin partners bottom">На территории
              Российской Федерации эксклюзивным дистрибьютором японской компании FURUKAWA
              BATTERY CO., LTD является компания «Юником».</p>
            <p class="assurance_thin partners top">
              «Юником» - один из ведущих российский дистрибьюторов автотоваров.
              По целому ряду товарных групп компания является лидером автомобильного
              рынка. Многие брэнды
              поставляются на российский рынок по
              эксклюзивным соглашениям с производителями.</p>
            <div class="partners_head_block">
              <h1 class="catalog_title white">25 лет</h1>
              <p class="partners_head">работы компании</p>
            </div>
            <div class="partners_head_block">
              <h1 class="catalog_title white">96%</h1>
              <p class="partners_head">довольных клиентов</p>
            </div>
          </div>
        </div>
      </div>
      <div  v-if="currentTab === 'Информация партнерам'">
        <div class="main_navigator small">
          <div class='main_navigator_row partners'>
            <div class='main_navigator_map partners'>
              <div class='main_navigator_map_block partners'>
                <a href="https://uniqom.ru/catalog/brand/category/36" style="width: fit-content" target="_blank">
                  <button class='footer_button partners'>Узнать цены</button>
                </a>
                <p class="partners_head">Зарегистрируйтесь на сайте компании, чтобы узнать цены</p>
              </div>
            </div>
          </div>
        </div>
        <div class="partners_body">
          <h2 class="catalog_info_titles partners">Преимущества<br>партнерам</h2>
          <div class="partners_block">
            <p class="catalog_info_titles partners">Персональный<br>менеджер</p>
            <p class="partners_block">Наш специалист ответит на все вопросы, приедет в
              ваш магазин и подскажет, какой товар будет пользоваться спросом именно
              у вашего покупателя.</p>
          </div>
          <div class="partners_block">
            <p class="catalog_info_titles partners">Маркетинговая<br>поддержка</p>
            <p class="partners_block">Предоставляем промо-материалы разного формата,
              которые помогут лучшерассказать о новом товаре покупателям.</p>
          </div>
          <div class="partners_block">
            <p class="catalog_info_titles partners">Подробное<br>обучение</p>
            <p class="partners_block">Наш региональный представитель поделится свежими
              знаниями о продукции брендов и лично покажет, как пользоваться новинками.</p>
          </div>
        </div>
        <div class="partners_line">
          <div class="partners_body line">
            <p class="catalog_info_titles line">Скидка до 20% на первые 2 месяца.<br><br>
                Чтобы сохранить скидку, необходимо выйти на закуп в 40 000 руб, </p>
            <a href="https://uniqom.ru/register" target="_blank">
              <button class='footer_button partners line'>
                WELCOME-ПЕРИОД
              </button>
            </a>
          </div>
        </div>
        <div class="partners_body small">
          <h2 class="catalog_info_titles partners">Удобная доставка</h2>
          <div class="partners_block small">
            <p class="catalog_info_titles partners small">Экспресс</p>
            <p class="partners_block small">Оформите заказ утром и товар будет у вас уже вечером.
              Бесплатная экспресс-доставка осуществляется дважды в день.</p>
          </div>
          <div class="partners_block small">
            <p class="catalog_info_titles partners small">Курьер</p>
            <p class="partners_block small">Оформите заказ и нужные автозапчасти
              будут у вас уже через 1,5 часа. Заказ от 2500 доставляем бесплатно.</p>
          </div>
          <div class="partners_block small">
            <p class="catalog_info_titles partners small">Транспортная<br>копания</p>
            <p class="partners_block small">Живете в отдаленном регионе? Доставим
              товар в ваш город транспортной компанией. Доставка до ТК - бесплатно.</p>
          </div>
        </div>
        <div class="main_navigator small">
          <div class='main_navigator_row partners'>
            <div class='main_navigator_map footer'>
              <div class='main_navigator_map_block footer'>
                <a href="https://uniqom.ru/register" target="_blank">
                  <button class='footer_button partners'>Зарегистрироваться</button>
                </a>
                <button class='main_nav_catalog partners'
                @click="currentTab = 'Контакты'">Найти филиал</button>
              </div>
              <div class="carousel_catalog_info">
                <carousel :items="1" :nav="false" :dots="false"
                    :margin="20" :autoWidth="true" :autoHeight="true"
                >
                  <a href="https://uniqom.ru/register" target="_blank">
                    <button class='footer_button partners'>Зарегистрироваться</button>
                  </a>
                  <button class='main_nav_catalog partners'
                  @click="(currentTab = 'Контакты')">Найти филиал</button>
                  <button class="button__visibility_hidden"></button>
                  <button class="button__visibility_hidden"></button>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <y-map
          v-if="currentTab === 'Контакты'"
          :data="offices"
          :key="offices.length"
          api_cities="offices/cities/fb"
          api_points="offices/points/fb"
      ></y-map>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import YMap from './YMap.vue';
import utils from '../utils';

export default {
  components: { YMap, carousel },
  metaInfo: {
    title: 'Партнерам',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
  data() {
    return {
      offices: [],
      currentTab: 'Информация партнерам',
      navigationTabs: ['Информация партнерам', 'Контакты'],
    };
  },
  methods: {
    openPage(link) {
      this.$root.$emit('openPage', link);
    },
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
    async getOffices() {
      this.offices = await this.fetchData('get', 'offices');
    },
  },
  beforeMount() {
    this.getOffices();
  },
};
</script>
