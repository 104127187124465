<template>
    <div>
        <div class='main_navigator'>
          <div class='main_navigator_selection'>
            <!-- eslint-disable -->
            <div class="main_selector">
              <ul class="header">
                <li
                  class="selector_item"
                  :class="{ active: CurrentSelectorTab === tab}"
                  v-for="(tab, index) in SelectorTabsList"
                  :key="index"
                  @click="(CurrentSelectorTab = tab) && (SelectedFilters = [])">
                    {{ tab }}
                </li>
              </ul>
              <div class="selection-group">
                <div
                    class="selection-item"
                    v-for="(item, ind1) in SelectorsList"
                    v-show="item.tab === CurrentSelectorTab"
                    :key="ind1"
                >
                  <input
                      autocomplete="new-password"
                      class="uk-input main_selector"
                      :placeholder="item.title"
                      :data-index="ind1"
                      @keyup="insertedSearchFilter($event, item.name)"
                      @focus="$event.target.nextSibling.classList.add('is-active')"
                      @blur="
                        $event.target.nextSibling.matches(':hover') ? '' :
                        $event.target.nextSibling.classList.remove('is-active')
                      "
                      @keypress.enter="autoInsertedSearchFilter($event)"
                      :disabled="!item.variants.length"
                      :id="item.name"
                  />
                  <div class="selection-item__list">
                    <div v-if="variant.title || variant.properties"
                         v-for="(variant, ind2) in item.variants"
                         v-show="showBySearch(item.name, variant)"
                         :key="ind2"
                         :data-value="variant.id || variant.title"
                         @click="onClickFetchData($event, ind1, item.name)"
                         class="selection-item__list-item">{{ variant.title || variant.properties }}</div>
                  </div>
                </div>
              </div>
              <button
                class="selector_button"
                :class="{ unavaliable: !showButton }"
                @click="showButton ? applyFilterParams() : popupOpen()"
              >
                Подбор аккумулятора
              </button>
            </div>
            <!-- eslint-enabled -->
          </div>
          <div class='main_navigator_catalog'>
            <div class='main_navigator_catalog_block'>
              <h2 class='main_nav_catalog_title'>Настоящие японские аккумуляторы</h2>
              <button class='main_nav_catalog' @click="openPage('/catalog')">Каталог</button>
            </div>
          </div>
          <div class='main_navigator_map'>
            <div class='main_navigator_map_block'>
              <h2 class='main_nav_map_title'>Где купить</h2>
              <button class='main_nav_map' @click="openPage('/map')">Карта</button>
            </div>
          </div>
        </div>
        <main-news></main-news>
        <main-catalog></main-catalog>
      </div>
</template>

<script>
import MainCatalog from './MainCatalog.vue';
import MainNews from './MainNews.vue';
import utils from '../utils';

export default {
  name: 'page-main',
  components: {
    MainCatalog,
    MainNews,
  },
  data() {
    return {
      CurrentSelectorTab: 'По автомобилю',
      SelectorTabsList: ['По автомобилю', 'По параметрам'],
      SelectedFilters: [],
      SelectedSearch: [],
      SelectorsList: [
        {
          tab: 'По автомобилю',
          title: 'Расположение руля',
          name: 'rudders',
          ref: ['marks'],
          variants: [{
            id: 1,
            title: 'Левый руль',
          },
          {
            id: 2,
            title: 'Правый руль',
          }],
        },
        {
          tab: 'По автомобилю',
          title: 'Производитель',
          name: 'marks',
          ref: ['models'],
          target: ['rudders'],
          variants: [],
        },
        {
          tab: 'По автомобилю',
          title: 'Модель',
          name: 'models',
          ref: ['carcases', 'engines'],
          target: ['rudders'],
          variants: [],
        },
        {
          tab: 'По автомобилю',
          title: 'Кузов',
          name: 'carcases',
          ref: ['engines'],
          target: ['models', 'rudders'],
          variants: [],
        },
        {
          tab: 'По автомобилю',
          title: 'Двигатель',
          name: 'engines',
          variants: [],
        },
        {
          tab: 'По параметрам',
          title: 'Стандарт',
          name: 'standards',
          ref: ['sizes'],
          variants: [],
          static: true,
        },
        {
          tab: 'По параметрам',
          title: 'Размеры',
          name: 'sizes',
          variants: [],
        },
      ],
    };
  },
  computed: {
    showButton() {
      if (this.$store.state.filters.car.length > 0 && this.CurrentSelectorTab === 'По автомобилю') return true;
      if (this.$store.state.filters.params.length > 0 && this.CurrentSelectorTab === 'По параметрам') return true;
      return false;
    },
  },
  methods: {
    openPage(link) {
      this.$root.$emit('openPage', link);
    },
    popupOpen() {
      this.$root.$emit('popupBox');
    },
    // Создаем ссылку с параметрами фильтров и переходим на страницу подбора
    applyFilterParams() {
      let target = '/catalog/selection';
      let separator = '?';
      if (this.CurrentSelectorTab === 'По автомобилю') target += `${separator}type=1`;
      if (this.CurrentSelectorTab === 'По параметрам') target += `${separator}type=2`;
      const map = new Map(Object.entries(this.SelectedFilters));
      map.forEach((value, key) => {
        separator = '&';
        target += `${separator + key}=${value}`;
      });
      document.location = target;
    },
    // Импорт функции
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
    // Выбираем item и получаем новые списки зависимых полей для input
    onClickFetchData(event, index, name) {
      const elem = event.target;
      const parent = elem.closest('.selection-group');
      const id = elem.dataset.value;
      this.choiceInput(event, id, name);
      this.getData(id, index, name, parent);
      this.setState(id, name);
    },
    setState(id, name) {
      if (this.CurrentSelectorTab === 'По автомобилю') {
        const payload = {
          type: 'car',
          data: {
            name_: name,
            id_: id,
          },
        };
        this.$store.commit('addFilter', payload);
      }
      if (this.CurrentSelectorTab === 'По параметрам') {
        const payload = {
          type: 'params',
          data: {
            name_: name,
            id_: id,
          },
        };
        this.$store.commit('addFilter', payload);
      }
    },
    // Получаем списки для выбранного параметра (SelectorsList[index]) и его зависимостей (.ref)
    async getData(id, index, name, parent = null) {
      const data = this.SelectorsList[index];
      this.SelectedFilters[name] = id;
      if (data.ref) {
        if (parent) this.clearList(data.ref, parent);
        await Promise.all(this.SelectorsList.map(async (item, i) => {
          await Promise.all(data.ref.map(async (ref) => {
            let entity = 'selection';
            if (item.tab === this.SelectorTabsList[1]) entity = 'selection/products';
            if (ref === item.name) {
              const rt = await this.setTargetLink(data.target, item.name);
              const target = `${item.name}?${data.name.slice(0, -1)}=${id}${rt}`;
              this.SelectorsList[i].variants = await this.fetchData(entity, target);
            }
          }));
        }));
      }
    },
    async setTargetLink(target, name) {
      let url = '';
      let { query } = this.$store.state || this.$route;
      if (undefined !== query) query = this.SelectedFilters;
      if (target && query) {
        await Promise.all(target.map(async (rt) => {
          if (query[rt] && name !== rt) url += `&${rt.slice(0, -1)}=${query[rt]}`;
        }));
      }
      return url;
    },
    // Меняем ввод input по клику на item
    choiceInput(event, id, name) {
      const parent = event.target.closest('.selection-item');
      const input = parent.querySelector('input');
      parent.querySelector('.selection-item__list').classList.toggle('is-active');
      input.value = event.target.innerText;
      document.getElementById(`pc-${input.id}`).value = event.target.innerText;
      document.getElementById(`tablet-${input.id}`).value = event.target.innerText;
      const payload = [id, name];
      this.$root.$emit('fetchFromMain', payload);
    },
    // Очищаем зависимые поля input по клику на лист
    clearList(refs, parent) {
      refs.forEach((ref) => {
        this.SelectorsList.forEach((element, index) => {
          if (ref === element.name) {
            delete this.SelectedFilters[element.name];
            this.SelectorsList[index].variants = [];
            const refChild = this.SelectorsList[index].ref;
            if (parent) {
              const option = parent.querySelector(`input[data-index="${index}"]`);
              option.value = null;
            }
            if (refChild) this.clearList(refChild, parent);
          }
        });
      });
    },
    // Фильтруем показ листов выпадающего списка по вводу input
    showBySearch(name, value) {
      const search = this.SelectedSearch[name];
      if (!search || search.length < 2) return true;
      return (value.title && value.title.toLowerCase().includes(search.toLowerCase()))
          || (value.properties && value.properties.toLowerCase().includes(search.toLowerCase()));
    },
    // Задаем фильтр по вводу input
    insertedSearchFilter(event, name) {
      this.SelectedSearch[name] = event.target.value;
      this.$forceUpdate();
    },
    // Задаем фильтр по клику enter на input
    autoInsertedSearchFilter(event) {
      const parent = event.target.closest('.selection-item');
      const list = parent.querySelectorAll('.selection-item__list-item');
      const BreakException = {};
      try {
        list.forEach((item) => {
          const text = item.innerText.toLowerCase();
          if (text.includes(event.target.value.toLowerCase())) {
            item.click();
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    // Получаем полный спискок данных для подбора по параметрам
    async getStaticData() {
      await Promise.all(this.SelectorsList.map(async (item, i) => {
        if (item.tab === this.SelectorTabsList[1] && item.static) {
          const target = `products/${item.name}`;
          this.SelectorsList[i].variants = await this.fetchData('selection', target);
        }
      }));
    },
  },
  metaInfo: {
    title: 'Настоящие японские аккумуляторы',
    titleTemplate: `${process.env.VUE_APP_NAME} - %s`,
  },
  mounted() {
    this.getStaticData();
    window.scrollTo(0, 0);
  },
};
</script>
