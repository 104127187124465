<template>
    <div>
      <div class="path-mobile">
        <p class="path-mobile">О компании</p>
      </div>
      <div class="about_head">
        <div class="about_head_info">
          <div class="path about">
            <a class="path blue" @click="openPage('/')"><p class="path">Главная</p></a>
            <p class="path blue selected"> | </p>
            <p class="path blue selected">О компании</p>
          </div>
          <h3 class="about_head_title">The Furukawa Battery Co., LTD</h3>
          <p class="about_head_top">
            Аккумуляторы производятся в Японии на трех заводах и поставляются во многие страны мира.
          </p>
          <div class="about_head_bottom">
            <p class="about_head_bottom">
              Японская компания, основанная в 1950 году. Занимается производством аккумуляторов
              как для автомобилей, так и для железнодорожного <br>транспорта, военной авиации,
              космических <br>спутников, а также аварийных систем жизнеобеспечения зданий и
              сооружений.
            </p>
            <p class="about_head_bottom">
              Сейчас компания является основным поставщиком автомобильных заводов Suzuki,
              HONDA, Toyota в Японии, имеет уникальные разработки, выгодно отличающие
              аккумуляторы FB как на внутреннем, <br>так и на российском рынке.
            </p>
            <p class="about_head_bottom">
              Продукция компании имеет международные сертификаты ISO 9001, ISO14001 и
              сертификат Российского стандарта.
            </p>
          </div>
          <img class="about_head" src="../assets/img/1950.svg" alt="1950.svg">
        </div>
      </div>
      <div class="about_body">
        <p class="about_body_text">На сегодняшний день Furukawa Battery Co., Ltd.
          является глобальной компанией и насчитывает 3 завода в Японии, 1 в Таиланде,
          1 в Индонезии.</p>
        <div class="about_body_production">
          <p class="about_body_madeBox">MADE<br>in<br>JAPAN</p>
          <div class="about_body_production_text">
            <p class="about_body_bold">Все серии* аккумуляторных батарей,<br>
              продающиеся в России, произведены в Японии.</p>
            <p class="about_body_annotation">*кроме Premium Gold SMF. Она производится на
              заводе в Таиланде из сырья поставляемого из Японии.</p>
          </div>
        </div>
        <div class="about_body_groups">
          <p class="about_body_bold">В группу компаний FURUKAWA входят:</p>
          <div class="about_body_groups_text">
            <div class="about_body_groups_column">
              <p
                  v-for="(item, index) in blockLeft"
                  :key="index"
                  class="about_body_text">
                {{ item.title }}<br>
                {{ item.description }}
              </p>
            </div>
            <div class="about_body_groups_column">
              <p
                  v-for="(item, index) in blockRight"
                  :key="index"
                  class="about_body_text">
                {{ item.title }}<br>
                {{ item.description }}
              </p>
              <p class="about_body_text">А так же много других компаний</p>
            </div>
          </div>
        </div>
        <div class="about_body_stat">
          <div class="about_body_stat_element">
            <img src="../assets/img/57.svg" alt="57.svg">
            <p class="about_stat_title">компаний</p>
          </div>
          <div class="about_body_stat_element">
            <img src="../assets/img/604.svg" alt="604.svg">
            <p class="about_stat_title">дочерних предприятий</p>
          </div>
          <div class="about_body_stat_element">
            <img src="../assets/img/251700.svg" alt="251700.svg">
            <p class="about_stat_title">сотрудников</p>
          </div>
        </div>
        <div class="about_body_bottom">
          <div class="about_body_bottom_desc">
            <div class="about_body_desc">
              <p class="about_body_bold">Японские аккумуляторы <br>FURUKAWA BATTERY</p>
              <p class="about_body_text">Это настоящее качество и надежность в
                сочетании с выдающимися характеристиками. Аккумуляторы FB выбраны
                известными автопроизводителями. Аккумуляторы Furukawa Battery -
                это уверенный запуск двигателя в любых ситуациях.</p>
            </div>
            <div class="about_body_desc_element">
              <img src="../assets/img/22.svg" alt="22.svg">
              <p class="about_stat_title">доля для OEM</p>
            </div>
          </div>
          <div class="about_body_bottom_stat">
            <div class="about_bottom_stat_element">
              <div class="about_bottom_stat_automarkers">
                <div class="about_automarkers_column">
                  <img src="../assets/img/suzuki.svg" alt="suzuki.svg">
                  <img class="about_automarkers_bottom" src="../assets/img/toyota.svg" alt="toyota.svg">
                </div>
                <div class="about_automarkers_column">
                  <img src="../assets/img/isuzu.svg" alt="isuzu.svg">
                </div>
              </div>
              <div class="about_bottom_stat_info">
                <p class="about_body_text">Автомобильные аккумуляторы: 7,7 млн. шт.<br><br>
                  Заводы в Японии: 5,7 млн. шт.<br><br>
                  Завод в Таиланде: 2,0 млн. шт.</p>
              </div>
            </div>
            <div class="about_bottom_stat_element">
              <div class="about_bottom_stat_automarkers">
                <div class="about_automarkers_column">
                  <img src="../assets/img/daihatsu.svg" alt="daihatsu.svg">
                  <img class="about_automarkers_bottom" src="../assets/img/honda.svg" alt="honda.svg">
                </div>
                <div class="about_automarkers_column">
                  <img src="../assets/img/hino.svg" alt="hino.svg">
                </div>
              </div>
              <div class="about_bottom_stat_info">
                <p class="about_body_text">Мотоциклетные аккумуляторы: 2,3 млн. шт.<br><br>
                  Заводы в Японии: 0,8 млн. шт.<br><br>
                  Завод в Таиланде: 1,5 млн. шт.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import utils from '../utils';

export default {
  data() {
    return {
      blockRight: null,
      blockLeft: null,
    };
  },
  methods: {
    openPage(link) {
      this.$root.$emit('openPage', link);
    },
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
  },
  async beforeMount() {
    const response = await this.fetchData('get', 'companies');
    if (response) {
      const companies = response ? response.data : null;
      this.blockLeft = companies.slice(0, companies.length / 2);
      this.blockRight = companies.slice(companies.length / 2);
    }
  },
  metaInfo: {
    title: 'О компании',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
};
</script>
