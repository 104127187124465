<template>
    <div>
      <div class="path-mobile">
        <p class="path-mobile">Гарантия</p>
      </div>
      <div class="assurance_header">
        <div class="assurance_head"
          v-if="currentTab === 'Условия гарантии'"
        >
          <div class="carousel_catalog show-sm">
            <carousel :items="1" :nav="false" :dots="false"
                :margin="24" :autoWidth="true" :autoHeight="true">
                <button
                    class="info_nav"
                    :class="{ active: currentTab === tab }"
                    v-for="(tab, index) in navigationTabs"
                    :key="index"
                    @click="(currentTab = tab)">
                      {{tab}}
                </button>
                <button class="button__visibility_hidden"></button>
            </carousel>
          </div>
          <div class="path about">
            <a class="path blue" @click="openPage('/')"><p class="path">Главная</p></a>
            <p class="path blue"> | </p>
            <p class="path blue">Техническая информация</p>
            <p class="path blue selected"> | </p>
            <p class="path blue selected">{{currentTab}}</p>
          </div>
          <div class="assurance_head_default_body">
            <h1 class="assurance_title">Гарантия</h1>
            <p class="assurance_head">Компания FB гордится качеством своей продукции и
              предоставляет гарантию на свои аккумуляторы 2 года с возможностью
              продления её на 3-ий год (см. условия в гарантийном талоне)</p>
            <p class="assurance_thin">На нашем сайте Вы сможете подробноознакомиться
              с условиями гарантии и подобрать сертифицированный автосервис</p>
            <p class="assurance_thin absolute">Помощником компании FB в развитии
              Гарантийного и сервисного обслуживания на территории РФ выступает
              эксклюзивный дистрибьютор компания «Юником».</p>
            <p class="assurance_note">*при условии использования в течении 2-х лет</p>
            <img class="assurance_head" src="../assets/img/3years.svg" alt="3years.svg">
          </div>
          <div class="assurance_nav_default">
            <button
                class="info_nav"
                :class="{ active: currentTab === tab }"
                v-for="(tab, index) in navigationTabs"
                :key="index"
                @click="(currentTab = tab)">
                  {{tab}}
            </button>
          </div>
        </div>
        <div class="assurance_head map"
          v-if="currentTab === 'Гарантийные центры'">
          <div class="carousel_catalog show-sm">
            <carousel :items="1" :nav="false" :dots="false"
                :margin="12" :autoWidth="true" :autoHeight="true">
                <button
                    class="info_nav"
                    :class="{ active: currentTab === tab }"
                    v-for="(tab, index) in navigationTabs"
                    :key="index"
                    @click="(currentTab = tab)">
                      {{tab}}
                </button>
            </carousel>
          </div>
          <div class="assurance_head_map_body">
            <p class="assurance_thin map">Все работы по восстановлению рабочих характеристик
              исправного аккумулятора оплачиваются конечным Потребителем. Потребителем
              также оплачивается диагностика электрооборудования автомобиля в случае,
              если выход из строя АКБ был с этим связан.<br><br>
              В случае выхода из строя АКБ по вине завода изготовителя, Потребитель все
              вышеперечисленные работы не оплачивает, если таковые проводились</p>
          </div>
          <div class="info_head_nav map">
            <button
                class="info_nav"
                :class="{ active: currentTab === tab }"
                v-for="(tab, index) in navigationTabs"
                :key="index"
                @click="(currentTab = tab)">
                  {{tab}}
            </button>
          </div>
        </div>
      </div>
      <div class="assurance_body"
        v-if="currentTab === 'Условия гарантии'">
        <div class="assurance_body_head">
          <p class="assurance_body">Гарантия распространяется на дефекты производства и материалов, которые могут
            быть выявлены в течение гарантийного срока. Гарантия действует, если Вы
            предоставляете сотруднику сервисного центра дефектный аккумулятор в чистом
            виде и полностью заполненный гарантийный талон* и чек.<br><br> *Гарантийный талон
            присутствует в каждой упаковке аккумулятора.</p>
          <a
              :href="require('@/static/documents/GarantTalon.pdf')"
              class="assurance_link"
              target="_blank"
              style="bottom: 39px"
          >
            Гарантийный талон
          </a>
          <a
              :href="require('@/static/documents/PamPotreb.pdf')"
              class="assurance_link"
              target="_blank"
          >
            Памятка потребителю
          </a>
        </div>
        <div class="assurance_block">
          <div class="assurance_column">
            <p class="about_body_bold">Гарантия распространяется на следующие дефекты:</p>
            <p>Низкая плотность электролита в любой банке аккумулятора (ниже 1,24) при его
              покупке <br><br>Производственные дефекты составных частей аккумулятора. <br>Обрыв
              цепи в аккумуляторе <br><br>Замыкание в банке аккумулятора <br><br>Высокий саморазряд
              (падение плотности в отключенном аккумуляторе на 0,01 г/см3 за сутки)</p>
          </div>
          <div class="assurance_column">
            <p><span class="info_bold">Гарантия также распространяется на следующие дефекты</span>
              (при условии исправности электрооборудования автомобиля и его соответствия
              комплектации с завода*):</p>
            <p>Разная плотность электролита по банкам аккумулятора (более 0,04 г/см3)<br><br>
              Пусковой ток меньше требований японского стандарта JIS** (при условии правильной
              эксплуатации аккумулятора)***</p>
          </div>
          <p class="assurance_block_note">* &nbsp;&nbsp; Проверка электрооборудования производится
            по желанию клиента и за его счет. <br>**&nbsp; JIS – японский национальный стандарт,
            устанавливающий требования к аккумуляторным батареям. <br>*** В холодное время года
            гарантия распространяетсяна этот дефект только на аккумуляторы, рекомендованные для
            использования в холодных регионах.</p>
        </div>
        <div class="assurance_block">
          <div class="assurance_column center">
            <p class="assurance_center"><span class="info_bold">Внимание!</span><br><br>
            Пробки на аккумуляторных батареях предназначены <br>исключительно для СТО, а не для
            самостоятельного доступа. <br><br>Если на момент обращения, АКБ имеет хотя бы один
            недостаток/дефект из списка (описанные выше в<br> 14-ти пунктах), то такой АКБ с
            гарантии снимается и обмену или возврату не подлежит.</p>
          </div>
          <div class="assurance_column wider">
            <p class="about_body_bold">Гарантия не распространяется на следующие дефекты,
              возникшие по причине неправильной эксплуатации:</p>
            <p>АКБ со следами вскрытия <br><br>АКБ с повышенной плотностью электролита (выше 1,29
              г/см3 при<br> максимально допустимом уровне электролита)
              <br><br>АКБ, уровень электролита
              которых ниже минимального уровня <br><br>АКБ, установленные без учета требований
              завода-изготовителя <br><br>АКБ, используемые с нарушениями инструкции по эксплуатации
              <br><br>АКБ со следами механического воздействия
              <br><br>АКБ со следами замерзания электролита
              <br><br>АКБ со следами термического воздействия (вздутия)
              <br><br>АКБ с оплавленными клеммами
              <br><br>АКБ со следами вытекшего электролита
              <br><br>АКБ, эксплуатировавшиеся на автомобиле с
              внесенными изменениями в его конструкцию, которые привели к выходу аккумулятора
              из строя (в том числе неисправная сигнализация, автозвук, доп. световые приборы)
              <br><br>АКБ, поврежденные из-за дефектов работы электрооборудования<br> автомобиля
              <br><br>АКБ, на которых был произведен самостоятельный ремонт и/или зарядка
              <br><br>Разряженные АКБ, которые после зарядки не пригодны для эксплуатации</p>
          </div>
        </div>
        <div class="assurance_block">
          <div class="assurance_column wider">
            <p class="assurance_table_title mobile">Минимальные (стандартные) токи
              холодной прокрутки (CCA) модельного ряда АКБ FURUKAWA BATTERY</p>
            <div id="table" class="assurance_table">
              <p class="assurance_table_title">Минимальные (стандартные) токи холодной прокрутки
                (CCA) модельного ряда АКБ FURUKAWA BATTERY</p>
              <div class="assurance_table_section">
                <div class="assurance_table_row">
                  <div class="assurance_table_element">
                    <p class="assurance_table_cell">Модель АКБ</p>
                    <p class="assurance_table_cell">CCA</p>
                  </div>
                  <div class="assurance_table_element desktop">
                    <p class="assurance_table_cell">Модель АКБ</p>
                    <p class="assurance_table_cell">CCA</p>
                  </div>
                </div>
              </div>
              <div class="assurance_table_section"
                v-for="(item, index) in catalog"
                :key="index">
                <div class="assurance_table_row">
                  <div class="assurance_table_element"
                    v-for="(row, index) in item.models"
                    :key="index">
                    <p class="assurance_table_cell">{{row.model}}</p>
                    <p class="assurance_table_cell">{{row.cca}}</p>
                  </div>
                  <div class="assurance_table_element desktop"
                    v-if="(item.models.length % 2) !== 0">
                    <p class="assurance_table_cell"></p>
                    <p class="assurance_table_cell"></p>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="rollup"
              class="button-roll__mobile"
              @click="tableRollUp()"
            >
              <img class="selection" src='../assets/img/down.png' alt="down.png">
            </div>
          </div>
          <div class="assurance_column center mobile">
            <p class="assurance_center right"><span class="info_bold">Гарантийный иск вступает в
              силу после передачи в точку продажи:</span><br><br>Бракованного аккумулятора
              <br><br>Правильно заполненного гарантийного талона<br><br>
              Технического заключения, составленного гарантийным сервисным центром, с
              пометкой о наличии в АКБ заводского дефекта</p>
            <p class="assurance_center right">Все работы, касающиеся восстановления рабочих
              характеристик, в т.ч.<br> полная зарядка АКБ, оплачиваются Потребителем. Также
              Потребителем оплачиваются работы, связанные с диагностикой электрооборудования
              автомобиля и устранением найденных неисправностей в случаях, когда <br>выход из
              строя АКБ был с этим связан.</p>
            <a class="assurance_link" href="/files/Токи CCA.XLSX" download>
              Минимальные (стандартные) токи холодной прокрутки
              (ССА) модельного ряда АКБ компании Furukawa Battery (скачать)</a>
          </div>
        </div>
      </div>
      <y-map
          v-if="currentTab === 'Гарантийные центры'"
          api_cities="garant/cities/fb"
          api_points="garant/points/fb"
      ></y-map>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import YMap from './YMap.vue';

export default {
  components: { YMap, carousel },
  data() {
    return {
      currentTab: 'Условия гарантии',
      navigationTabs: ['Условия гарантии', 'Гарантийные центры'],
      catalog: [
        {
          models: [
            { model: '40B19R/L', cca: '270' },
            { model: '46B24R/L', cca: '320' },
            { model: '55B24R/L', cca: '340' },
            { model: '55D23R/L', cca: '370' },
            { model: '75D23R/L', cca: '450' },
            { model: '80D26R/L', cca: '475' },
            { model: '95D31R/L', cca: '550' },
          ],
        },
        {
          models: [
            { model: '46B19R/L', cca: '320' },
            { model: '70B24R/L', cca: '405' },
            { model: '85D23R/L', cca: '500' },
            { model: '110D26R/L', cca: '550' },
            { model: '125D31R/L', cca: '700' },
          ],
        },
        {
          models: [
            { model: '55B19R/L', cca: '350' },
            { model: '75B24R/L', cca: '450' },
            { model: '100D23R/L', cca: '530' },
            { model: '125D26R/L', cca: '600' },
            { model: '145D31R/L', cca: '750' },
          ],
        },
        {
          models: [
            { model: '105D31R/L', cca: '630' },
          ],
        },
        {
          models: [
            { model: '355LN1', cca: '300' },
            { model: '390LN3 IS', cca: '550' },
            { model: '375LN2 IS', cca: '500' },
            { model: '400LN4 IS', cca: '600' },
          ],
        },
        {
          models: [
            { model: 'K-42/B19L', cca: '270' },
            { model: 'S-95/D26L', cca: '550' },
            { model: 'N-55/B24L', cca: '320' },
            { model: 'T-115/D31L', cca: '680' },
            { model: 'Q-85/D23L', cca: '470' },
          ],
        },
        {
          models: [
            { model: 'LBN1 (DIN 45)', cca: '330' },
            { model: 'LN3 (DIN 75)', cca: '570' },
            { model: 'LN2 (DIN 65)', cca: '420' },
            { model: 'LN5 (DIN 100)', cca: '710' },
          ],
        },
      ],
    };
  },
  methods: {
    openPage(link) {
      this.$root.$emit('openPage', link);
    },
    tableRollUp() {
      const table = document.getElementById('table');
      const button = document.getElementById('rollup');
      const height = '1167px';
      if (table.style.height === height) {
        button.style.transform = 'rotate(360deg)';
        table.style.height = '391px';
      } else {
        button.style.transform = 'rotate(180deg)';
        table.style.height = height;
      }
    },
  },
  metaInfo: {
    title: 'Гарантия',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
};
</script>
