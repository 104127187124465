<template>
    <footer>
      <div class='footer'>
        <div class='footer_logo'>
          <img class='footer_img_logo' src='../assets/FB_logo_footer.svg' alt="footer_logo">
          <h2 class='footer_title'>Настоящие японские аккумуляторы</h2>
        </div>
        <div class='footer_nav'>
          <div class='footer_contacts'>
            <div class='footer_contacts_raw'>
              <p class='footer_contacts'>г. Владивосток, ул. Днепровская, 104</p>
              <p class='footer_contacts'>8 (423) 279 02 79</p>
            </div>
            <div class='footer_contacts_raw'>
              <a @click="openPage('/partners')"><button class='footer_button filled'>Все контакты</button></a>
              <button
                class='footer_button'
                @click="openModalBox()">Написать нам</button>
            </div>
          </div>
          <div class='footer_catalog'>
            <div class='footer_catalog_column'>
              <a class='footer_catalog' @click="openPage('/catalog')">Каталог</a>
              <a class='footer_catalog' @click="openPage('/about')">О компании</a>
              <a class='footer_catalog' @click="openPage('/map')">Где купить</a>
            </div>
            <div class='footer_catalog_column'>
              <a class='footer_catalog' @click="openPage('/assurance')">Гарантия</a>
              <a class='footer_catalog' @click="openPage('/info')">Техническая информация</a>
              <a class='footer_catalog' @click="openPage('/partners')">Для партнеров</a>
            </div>
          </div>
        </div>
      </div>
      <div id="modalBox" class="modal" @click="closeModalBox($event)">
        <div class="modal-content">
          <div>
            <span class='close' @click="closeModalBox('close')">&times;</span>
            <h3 class="modalBox_header">Обратная связь</h3>
          </div>
          <div class="modalBox_inputs">
            <div class="modalBox_group" :class="{error : errors && errors.name}">
              <input
                  id="report-name"
                  class="uk-input modal"
                  type="text"
                  name="name"
                  @focus="checkModalValue"
                  @blur="checkModalValue"
              />
              <p class="modalBox_text placeholder">Укажите имя</p>
              <div class="modalBox_error" v-if="errors && errors.name">
                {{ errors.name }}
              </div>
            </div>

            <div class="modalBox_group" :class="{error : errors && errors.email}">
                <input
                    id="report-email"
                    class="uk-input modal"
                    type="email"
                    name="email"
                    @focus="checkModalValue"
                    @blur="checkModalValue"
                />
                <p class="modalBox_text placeholder">Укажите почту</p>
                <div class="modalBox_error" v-if="errors && errors.email">
                    {{ errors.email }}
                </div>
            </div>
          </div>
          <div class="modalBox_inputs">
            <div class="modalBox_group" :class="{error : errors && errors.message}">
              <textarea
                  id="report-message"
                  class="uk-textarea modal"
                  rows="4"
                  @focus="checkModalValue"
                  @blur="checkModalValue"
              ></textarea>
              <p class="modalBox_text placeholder">Ваше сообщение</p>
              <div class="modalBox_error" v-if="errors && errors.message">
                {{ errors.message }}
              </div>
            </div>
          </div>
          <div
              class="modalBox_status_message"
              :class="status ? 'success' : 'error'"
              v-if="message"
              v-show="!errors"
          >
            {{ message }}
          </div>
          <button class="modalBox_button" @click="recaptcha">
            <span v-if="spin" uk-spinner></span>
            Отправить
          </button>
        </div>
      </div>
    </footer>
</template>

<script>
import axios from 'axios';

export default {
  name: 'the-footer',
  data() {
    return {
      spin: false,
      errors: null,
      message: null,
      status: false,
    };
  },
  methods: {
    openPage(link) {
      this.$root.$emit('openPage', link);
    },
    openModalBox() {
      const modal = document.getElementById('modalBox');
      modal.style.display = 'block';
    },
    closeModalBox(event) {
      const modal = document.getElementById('modalBox');
      if (event.target === modal || event === 'close') {
        modal.style.display = 'none';
      }
    },
    postFeedbackForm(data) {
      this.errors = null;
      this.message = null;
      axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/post-form`, data)
        .then((response) => {
          setTimeout(() => {
            this.spin = false;
          }, 350);
          this.status = response.data.success;
          this.message = response.data.message;
          if (response.data.success) {
            const modal = document.getElementById('modalBox');
            document.getElementById('report-name').value = null;
            document.getElementById('report-email').value = null;
            document.getElementById('report-message').value = null;
            modal.style.display = 'none';
          }
          if (!response.data.success) {
            this.errors = response.data.inputs;
          }
        });
    },
    async recaptcha() {
      this.spin = true;
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      const report = {
        name: document.getElementById('report-name').value,
        email: document.getElementById('report-email').value,
        message: document.getElementById('report-message').value,
        token,
      };
      this.postFeedbackForm(report);
    },
    checkModalValue(event) {
      if (!event.target.value) {
        event.target.nextSibling.classList.toggle('placeholder');
      }
    },
  },
  mounted() {
    this.$root.$on('modalBox', () => {
      this.openModalBox();
    });
  },
};
</script>
