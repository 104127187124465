<template>
  <div class="page_map">
      <div class="path-mobile">
        <p class="path-mobile">Где купить</p>
      </div>
    <y-map></y-map>
  </div>
</template>

<script>
import YMap from './YMap.vue';

export default {
  components: { YMap },
  metaInfo: {
    title: 'Где купить',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
};
</script>
