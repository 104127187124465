<template>
  <div>
    <div
        class="selection_body">
        <div class="path">
            <a class="path grey" @click="openPage('/')"><p class="path">Главная</p></a>
            <p class="path grey"> | </p>
            <a class="path grey" @click="openPage('/catalog')"><p class="path">Каталог</p></a>
            <p class="path grey"> | </p>
            <p class="path grey selected">Результат подбора</p>
        </div>
        <div
          class="selection_result_info"
          v-if="filters && '1' === this.$route.query.type"
        >
            <div class="selection_result_info_item">
              <p class="selection_result_info_item_text">Расположение руля:</p>
              <p class="selection_result_info_item_text">
                {{ filters.rudder ? filters.rudder : '-' }}
              </p>
            </div>
            <div class="selection_result_info_item">
              <p class="selection_result_info_item_text">Производитель:</p>
              <p class="selection_result_info_item_text" v-if="'mark.title' in filters"> {{ filters.mark.title }} </p>
              <p class="selection_result_info_item_text">
                {{ filters.mark ? filters.mark : '-' }}
              </p>
            </div>
            <div class="selection_result_info_item">
              <p class="selection_result_info_item_text">Модель автомобиля:</p>
              <p class="selection_result_info_item_text">
                {{ filters.model ? filters.model : '-' }}
              </p>
            </div>
            <div class="selection_result_info_item">
              <p class="selection_result_info_item_text">Кузов</p>
              <p class="selection_result_info_item_text">
                {{ filters.carcase ? filters.carcase : '-' }}
              </p>
            </div>
            <div class="selection_result_info_item">
              <p class="selection_result_info_item_text">Двигатель</p>
              <p class="selection_result_info_item_text">
                {{ filters.engine ? filters.engine : '-' }}
              </p>
            </div>
        </div>
        <div
          class="selection_result_info"
          v-if="filters && '2' === this.$route.query.type"
        >
            <div class="selection_result_info_item">
              <p class="selection_result_info_item_text">Стандарт:</p>
              <p class="selection_result_info_item_text">
                {{ filters.standard ? filters.standard : '-' }}
              </p>
            </div>
            <div class="selection_result_info_item">
              <p class="selection_result_info_item_text">Размеры:</p>
              <p class="selection_result_info_item_text">
                {{ filters.size ? filters.size : '-' }}
              </p>
            </div>
        </div>
        <div class="selection_result_empty" v-if="filters && !catalog.length">
          <div class="selection_result_empty_mess">Ничего не найдено.</div>
        </div>
        <div class="selection_result_body">
            <div class="selection_result_body_item"
                v-for="(item, index) in catalog"
                :key=index>
                <div class="selection_result_body_item_head">
                    <div class="selection_result_head_block">
                        <a><img
                            v-if="item.filename"
                            class="selection_result_image"
                            :src="getStaticImage('products', item.filename)"
                            :alt="item.filename"
                        />
                          <img
                             v-else
                             class="selection_result_image"
                             :src="getStaticImage('categories', item.defaultFile)"
                             :alt="item.filename"
                          />
                        </a>
                    </div>
                    <div class="selection_result_head_block">
                        <p class="selection_result_head_text">Серия</p>
                        <a @click="openCatalogPage(item.serie, item.category)">
                          <p class="selection_result_head_text title">
                            {{item.category}}
                          </p>
                        </a>
                    </div>
                    <div class="selection_result_head_block">
                        <p class="selection_result_head_text">Артикул</p>
                        <p class="selection_result_head_text article">{{ item.article }}</p>
                    </div>
                    <div
                      v-if="item.properties"
                      class="selection-suitables"
                    >
                      <img
                        class="catalog_suitable"
                        v-for="(fit, index) in item.properties"
                        :key="index"
                        :src="getStaticImage('icons', fit.icon)"
                        :alt="fit.icon"
                      />
                    </div>
                </div>
                 <div class="selection_result_body_item_char">
                    <p class="selection_result_char">{{ 'Емкость' }} <br> {{ '(A/ч)' }}</p>
                    <p class="selection_result_char">{{item.capacity ? item.capacity : ' н/д ' }}</p>
                </div>
                <div class="selection_result_body_item_char">
                  <p class="selection_result_char">{{ 'Пусковой ток' }} <br> {{ '(A/ч)' }}</p>
                  <p class="selection_result_char">{{item.electricity ? item.electricity : ' н/д ' }}</p>
                </div>
                <div class="selection_result_body_item_char">
                  <p class="selection_result_char">{{ 'ДхШхВ' }} <br/> {{ '(мм)' }}</p>
                  <p class="selection_result_char">{{item.size ? item.size : ' н/д ' }}</p>
                </div>
                <div class="selection_result_body_item_char">
                  <p class="selection_result_char">{{ 'Вес' }} <br/> {{ '(кг)' }}</p>
                  <p class="selection_result_char">{{item.weight ? item.weight : ' н/д ' }}</p>
                </div>
                <div class="selection_result_body_item_char">
                  <p class="selection_result_char">{{ 'Напряжение' }} <br/> {{ '(V)' }}</p>
                  <p class="selection_result_char">{{item.voltage ? item.voltage : ' н/д ' }}</p>
                </div>
                <div class="selection_result_body_item_char">
                  <p class="selection_result_char">{{ 'Тип клемм' }}</p>
                  <p class="selection_result_char">{{item.clem ? item.clem : ' н/д ' }}</p>
                </div>
                <div class="selection_result_body_item_char">
                  <p class="selection_result_char">{{ 'Стандарт' }}</p>
                  <p class="selection_result_char">{{item.standard ? item.standard : ' н/д ' }}</p>
                </div>
                <div class="selection_result_body_item_char">
                  <p class="selection_result_char">{{ 'Полярность' }}</p>
                  <p class="selection_result_char">{{item.polarity ? item.polarity : ' н/д ' }}</p>
                </div>
            </div>
        </div>
        <div class="selection_body_buttons">
            <a @click="openPage('/map')"><button class="selection_body_button">Где купить</button></a>
            <a @click="openPage('/catalog')"><button class="selection_body_button">Вся продукция</button></a>
        </div>
        <div class="selection_help">
            <div class="selection_help_text">
                <p class="selection_help_info">
                    Данный каталог поможет вам правильно подобрать
                    необходимый для вашего автомобиля аккумулятор, ознакомиться
                    с его характеристиками.
                </p>
                <p class="selection_help_info">
                    Если у Вас есть вопросы, то вы можете написать нам.
                </p>
            </div>
            <button
              class="selection_write_button"
              @click="openModalBox()"
            >
              Написать нам
            </button>
        </div>
    </div>
  </div>
</template>

<script>
// import EventBus from '../event-bus';
import utils from '../utils';

export default {
  data() {
    return {
      filters: null,
      catalog: [],
    };
  },
  methods: {
    openPage(link) {
      this.$root.$emit('openPage', link);
    },
    openModalBox() {
      this.$root.$emit('modalBox');
    },
    openCatalogPage(series, category) {
      const tab = {
        path: '/catalog',
        value: { tab: series, item: category },
      };
      this.$store.commit('setTab', tab);
      this.$router.push({ path: tab.path });
    },
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
    getStaticImage(type, name) {
      const { getStaticImage } = utils();
      return getStaticImage(type, name);
    },
    async getCatalog() {
      const map = new Map(Object.entries(this.$route.query));
      this.$store.commit('setQuery', this.$route.query);
      let type = null;
      let separator = '?';
      let target = '';
      map.forEach((value, key) => {
        if (key === 'type') {
          if (value === '1') type = 'selection/automobile';
          if (value === '2') type = 'selection/parameters';
        } else {
          target += `${separator + key.slice(0, -1)}=${value}`;
          separator = '&';
        }
      });
      if (type && target) {
        target = type + target;
        const response = await this.fetchData('get', target);
        this.catalog = response.data;
        this.filters = response.filters;
      }
    },
  },
  metaInfo: {
    title: 'Подбор аккумулятора',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
  created() {
    this.getCatalog();
  },
  mounted() {
    const header = document.getElementById('header');
    const headerMobile = document.getElementById('header-mobile');
    const body = document.getElementById('body');
    header.classList.add('fixed');
    headerMobile.classList.add('fixed');
    if (this.$route.name === 'main') {
      body.classList.add('body-fixed__default');
    } else {
      body.classList.add('body-fixed');
    }
  },
};
</script>
