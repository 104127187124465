<template>
  <div>
    <div class="catalog_body">
      <div class="path catalog">
        <a class="path grey" @click="openPage('/')"><p class="path">Главная</p></a>
        <p class="path grey"> | </p>
        <p class="path grey selected">Каталог</p>
      </div>
      <div class='catalog_series_nav'
        :class="{ loading: isLoad.series}"
      >
          <button
            class='main_catalog_nav'
            :class="{ active: selectedTab === tab.title }"
            v-for="(tab, index) in catalogTabs"
            :key="index"
            @click="getCategories(tab)"
          >
                {{ tab.title }}
          </button>
      </div>
      <div class='catalog_battery_nav'
        :class="{ loading: isLoad.categories }"
      >
        <button
          class='catalog_battery_nav'
          :class="{ active: selectedBattery === tab.title }"
          v-for="(tab, index) in categories"
          :key="index"
          @click="getContent(tab, index)"
        >
          {{ tab.title }}
        </button>
      </div>
      <div class="loader-box loader-box-flex" v-if="isLoad.content">
          <div class="loading-item-bg loading-item-flex">
            <div class="loader-box-flex loading_col-2">
              <div class="loading loading-item-md"></div>
              <div class="loading loading-item-md"></div>
              <div class="loading loading-item-bg"></div>
            </div>
            <div class="loading loading_col-2"></div>
          </div>
          <div class="loading loading-item-md"></div>
          <div class="loading loading-item-md"></div>
      </div>
      <div v-show="!isLoad.content">
        <div class="carousel_catalog show-md">
          <carousel :key="catalogTabs.length" :items="1" :nav="false" :dots="false"
                  :margin="24" :autoWidth="true" :autoHeight="true">
            <button
                class='main_catalog_nav'
                :class="{ active: selectedTab === tab.title }"
                v-for="(tab, index) in catalogTabs"
                :key="index"
                @click="getCategories(tab)">
                  {{ tab.title }}
            </button>
          </carousel>
        </div>
        <div class="carousel_catalog show-md">
          <carousel :key="carouselKey" :items="1" :nav="false" :dots="false"
                  :margin="12" :autoWidth="true" :autoHeight="true">
            <button
              class='catalog_battery_nav'
              :class="{ active: selectedBattery === tab.title }"
              v-for="(tab, index) in categories"
              :key="index"
              @click="getContent(tab, index)">
                {{tab.title }}
            </button>
          </carousel>
        </div>
        <!-- TITLE/TABLE -->
        <div
           class="catalog_item"
           v-if="category">
          <div
              v-if="category"
              class="catalog_item_column">
            <h1 class="catalog_title">{{ category.title }}</h1>
            <div
                v-if="category.properties"
                class="catalog_item_suitable">
              <img
                  class="catalog_suitable"
                  v-for="(fit, index) in category.properties"
                  :key="index"
                  :src="getStaticImage('icons', fit.icon)"
                  :alt="fit.icon"/>
            </div>
            <div class="catalog_model_column">
              <div class="catalog_model_title">
                <p class="catalog_model_title">Модель</p>
                <p class="catalog_model_title">Емкость, Ah<br>
                    <span class="catalog_model_title">при 20-ти часовом разряде</span>
                </p>
                <p class="catalog_model_title">Пусковой ток, A</p>
              </div>
              <div class="selection_result_empty" v-if="!catalog.length">
                <div class="selection_result_empty_mess">На данный момент товаров нет.</div>
              </div>
              <div
                  class="catalog_model_row"
                  v-for="(row, index) in catalog"
                  :key=index>
                <p
                    class="catalog_model_item model"
                    :class="{ grey: (index+1)%2 === 0 }"
                    @click="openModalBox(row, category.filename)">
                  {{ row.title }} {{ row.postfix }}
                </p>
                <p
                    class="catalog_model_item"
                    :class="{ grey: (index + 1) % 2 === 0 }">
                  {{ row.capacity ? row.capacity : 'н/д'}}
                </p>
                <p
                    class="catalog_model_item"
                    :class="{ grey: (index + 1) % 2 === 0 }">
                  {{ row.electricity ? row.electricity : 'н/д'}}
                </p>
              </div>
            </div>
          </div>
          <!-- IMAGE -->
          <div
              v-if="content"
              :key="category.id"
              class="catalog_item_column">
            <img
                class="catalog_item_image"
                :src="getStaticImage('categories', content.filename)"
                :alt="content.filename"
            />
          </div>
          <!-- DESCRIPTION -->
          <div
              v-if="content && content.content"
              class="catalog_item_section">
            <div class="catalog_section_row">
              <div
                  class="catalog_section_desc_block"
                  v-for="(section, index) in content.content"
                  :key="index">
                <p
                  class="catalog_info_titles"
                  v-if="section.title.length !== 0">
                  {{ section.title }}
                </p>
                <p
                  class="catalog_description_left"
                  v-if="section.blockLeft.length !== 0"
                  v-html="section.blockLeft"
                ></p>
                <p
                  class="catalog_description_right"
                  v-if="section.blockRight.length !== 0"
                  v-html="section.blockRight"
                ></p>
              </div>
            </div>
          </div>
          <!-- CHARACTERISTICS -->
          <div
              class="catalog_item_section"
              v-if="content && content.charact">
            <h2 class="catalog_info_titles">Характеристики:</h2>
            <div class=catalog_section_list>
              <div class="catalog_list_column">
                <ul class="catalog_section_char"
                      v-for="(char, index) in content.charact"
                      v-show="(index + 1) % 2 !== 0"
                      :key="index">
                  <li class="catalog_char_list">{{ char.title }}</li>
                </ul>
              </div>
              <div class="catalog_list_column">
                <ul class="catalog_section_char"
                      v-for="(char, index) in content.charact"
                      v-show="(index + 1) % 2 === 0"
                      :key="index">
                  <li class="catalog_char_list">{{ char.title }}</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- TECHNOLOGIES -->
          <div class="catalog_item_section"
               v-if="content && content.tech">
            <p class="catalog_info_titles">Технологии</p>
            <div class="catalog_section_row">
              <div class="catalog_section_block"
                   v-for="(tech, index) in technologies"
                   :key="index">
                <p
                    class="catalog_section_text"
                   v-show="tech.info.length !== 0">
                  {{tech.info}}
                </p>
                <div
                    class="catalog_tech_images"
                    v-show="tech.images.length !== 0">
                    <div
                        v-for="(image, index) in tech.images"
                        :key="index">
                        <img
                            class="catalog_tech_img"
                            :src="require(`../assets/img/${image.img}.png`)"
                            :alt="image.img"
                        />
                        <p class="catalog_img_title">{{image.title}}</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <a @click="openPage('/map')"><button class="selection_write_button">Где купить</button></a>
        </div>
        <!-- POP-UP -->
        <div id="modalBoxTable" class="modal" @click="closeModalBox($event)">
          <div class="modal-table">
            <div>
              <span class='close blue' @click="closeModalBox('close')">&times;</span>
              <h3 class="modalBox_table">Данные аккумуляторной батареи</h3>
            </div>
            <div class="selection_result_body mobile">
              <div class="selection_result_body_item">
                  <div class="selection_result_body_item_head">
                      <div class="selection_result_head_block">
                          <img
                              v-if="selectedModel.img"
                              class="selection_result_image"
                              :src="getStaticImage('categories', selectedModel.img)"
                              :alt="selectedModel.img"
                          />
                      </div>
                      <div class="selection_result_head_block">
                          <p class="selection_result_head_text">Серия</p>
                          <p class="selection_result_head_text title grey">
                            {{selectedBattery}}
                          </p>
                      </div>
                      <div class="selection_result_head_block">
                          <p class="selection_result_head_text">Артикул</p>
                          <p class="selection_result_head_text article">
                            {{selectedModel.title}} {{selectedModel.postfix}}
                          </p>
                      </div>
                  </div>
                  <div class="selection_result_body_item_char">
                      <p class="selection_result_char">Емкость<br>(А/ч)</p>
                      <p class="selection_result_char">
                        {{ selectedModel.capacity ? selectedModel.capacity : 'н/д' }}
                      </p>
                  </div>
                  <div class="selection_result_body_item_char">
                      <p class="selection_result_char">Пусковой<br>ток (А)</p>
                      <p class="selection_result_char">
                        {{ selectedModel.electricity ? selectedModel.electricity : 'н/д' }}
                      </p>
                  </div>
                  <div class="selection_result_body_item_char">
                      <p class="selection_result_char">ДхШхВ<br>(мм)</p>
                      <p class="selection_result_char">
                        {{ selectedModel.size ? selectedModel.size : 'н/д' }}
                      </p>
                  </div>
                  <div class="selection_result_body_item_char">
                      <p class="selection_result_char">Вес<br>(кг)</p>
                      <p class="selection_result_char">
                        {{ selectedModel.weight ? selectedModel.weight : 'н/д' }}
                      </p>
                  </div>
                  <div class="selection_result_body_item_char">
                      <p class="selection_result_char">Напряжение<br>(V)</p>
                      <p class="selection_result_char">
                        {{ selectedModel.voltage ? selectedModel.voltage : 'н/д' }}
                      </p>
                  </div>
                  <div class="selection_result_body_item_char">
                      <p class="selection_result_char">Тип клемм</p>
                      <p class="selection_result_char">
                        {{ selectedModel.clem ? selectedModel.clem : 'н/д' }}
                      </p>
                  </div>
                  <div class="selection_result_body_item_char">
                      <p class="selection_result_char">Стандарт</p>
                      <p class="selection_result_char">
                        {{ selectedModel.standard ? selectedModel.standard : 'н/д' }}
                      </p>
                  </div>
                  <div class="selection_result_body_item_char">
                      <p class="selection_result_char">Полярность</p>
                      <p class="selection_result_char">
                        {{ selectedModel.polarity ? selectedModel.polarity : 'н/д' }}
                      </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import EventBus from '../event-bus';
import utils from '../utils';

export default {
  components: { carousel },
  props: [
    'selectedTab_prop',
    'selectedBattery_prop',
  ],
  methods: {
    openPage(link) {
      this.$root.$emit('openPage', link);
    },
    openModalBox(model, img) {
      this.selectedModel = model;
      this.selectedModel.img = img;
      const modal = document.getElementById('modalBoxTable');
      modal.style.display = 'block';
    },
    closeModalBox(event) {
      const modal = document.getElementById('modalBoxTable');
      if (event.target === modal || event === 'close') {
        modal.style.display = 'none';
      }
    },
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
    getStaticImage(type, name) {
      const { getStaticImage } = utils();
      return getStaticImage(type, name);
    },
    async getCategories(tab) {
      this.isLoad.categories = true;
      this.isLoad.content = true;
      this.selectedTab = tab.title;
      const target = `categories?serie=${tab.id}`;
      const response = await this.fetchData('get', target);
      if (response) {
        const index = 0;
        this.categories = response.data;
        await this.getContent(response.data[index], index);
      }
    },
    async getContent(tab, index) {
      this.carouselKey -= 1;
      this.isLoad.content = true;
      let itemId = tab.id;
      if (this.$store.state.page['/catalog']) {
        this.categories.forEach((el) => {
          if (el.title === this.$store.state.page['/catalog'].item) {
            this.category = el;
            this.selectedBattery = el.title;
            itemId = el.id;
          }
        });
        this.$store.commit('clearPage');
      } else {
        this.category = this.categories[index];
        this.selectedBattery = tab.title;
      }
      const target = `content/categories?id=${itemId}`;
      await this.getCatalog(tab);
      this.content = await this.fetchData('get', target);
    },
    async getCatalog(tab) {
      const target = `products?category=${tab.id}`;
      const response = await this.fetchData('get', target);
      if (response) {
        this.catalog = response.data;
      }
      setTimeout(() => {
        this.isLoad.categories = false;
        this.isLoad.content = false;
      }, 375);
    },
  },
  data() {
    return {
      isLoad: {
        series: true,
        categories: true,
        content: true,
      },
      selectedModel: { img: '' },
      catalogTabs: [],
      selectedTab: null,
      selectedBattery: null,
      content: null,
      categories: null,
      category: null,
      catalog: [],
      carouselKey: -1,
      technologies: [
        {
          info: 'Технология Book-mold Casting при производстве анодной пластины значительно сокращает разрушение анода в '
              + 'процессе эксплуатации, состояние которого во многом определяет срок службы батареи. Метод использования '
              + 'литейной формы в виде книги, в сравнении с иными методами, обеспечивает стойкость при растяжении и более '
              + 'плотное прилегание активных веществ к решетке и увеличивает срок службы батареи. За счет этого мы добились '
              + 'предотвращения снижения или потери контакта с активным веществом и предотвращения короткого замыкания.',
          images: [],
        },
        {
          info: '',
          images: [
            { img: 'tech_1', title: 'Inner-Cell Stright Connected Elements' },
            { img: 'tech_2', title: 'Inner-Lug Super Strong Grid' },
            { img: 'tech_3', title: 'Low Impedance PE Envelop Separator' },
          ],
        },
        {
          info: 'Компьютерное моделирование параметров решетки и улучшенный свинцово-кальциевый сплав обеспечивают '
              + 'батарее увеличенные значения пускового тока и гарантируют уверенный запуск двигателя даже при низких температурах.',
          images: [],
        },
        {
          info: 'Метод холодной штамповки для катодных пластин обеспечивает большую производительность и стабильность '
              + 'качества пластин. Этот метод позволяет производить более тонкие пластины и таким образом обеспечивать '
              + 'возможность производства различных моделей.',
          images: [],
        },
      ],
    };
  },
  async beforeMount() {
    const response = await this.fetchData('get', 'series');
    if (response) {
      if (this.$store.state.page['/catalog']) {
        response.data.forEach((el) => {
          if (el.title === this.$store.state.page['/catalog'].tab) {
            this.getCategories(el);
          }
        });
      } else {
        await this.getCategories(response.data[0]);
      }
      this.catalogTabs = response.data;
      this.isLoad.series = false;
    }
  },
  metaInfo: {
    title: 'Каталог',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
  mounted() {
    EventBus.$on('BatteryClicked', (selectedTab, selectedBattery) => {
      this.selectedTab_prop = selectedTab;
      this.selectedBattery = selectedBattery;
    });
    const header = document.getElementById('header');
    const body = document.getElementById('body');
    header.classList.add('fixed');
    if (this.$route.name === 'main') {
      body.classList.add('body-fixed__default');
    } else {
      body.classList.add('body-fixed');
    }
  },
};
</script>

<style lang="scss">
  @import '@/assets/sass/_loading.scss';
</style>
