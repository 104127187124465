<template>
    <div>
      <div class="path-mobile">
        <p class="path-mobile">Техническая информация</p>
      </div>
      <div class="info_head">
        <div class="info_head_content">
          <div class="path about">
            <a class="path blue" @click="openPage('/')"><p class="path">Главная</p></a>
            <p class="path blue"> | </p>
            <p class="path blue">Техническая информация</p>
            <p class="path blue selected"> | </p>
            <p class="path blue selected">{{currentTab}}</p>
          </div>
          <div class="info_head_nav">
            <button
                class="info_nav"
                :class="{ active: currentTab === tab }"
                v-for="(tab, index) in navigationTabs"
                :key="index"
                @click="(currentTab = tab)">
                  {{tab}}
            </button>
          </div>
        </div>
        <div class="carousel_catalog_info">
            <carousel :items="2" :nav="false" :dots="false"
                :margin="24" :autoWidth="true" :autoHeight="true">
                <button
                    class="info_nav"
                    :class="{ active: currentTab === tab }"
                    v-for="(tab, index) in navigationTabs"
                    :key="index"
                    @click="(currentTab = tab)">
                      {{tab}}
                </button>
            </carousel>
          </div>
      </div>
      <!-- ПОДБОР АККУМУЛЯТОРА JIS -->
      <div v-if="currentTab === 'Подбор аккумулятора JIS'">
        <div class="info_head_body long">
          <div class="info_head_content long">
            <p class="info_head_title long">Как <br>подобрать аккумулятор стандарта <br>JIS</p>
            <img class="info_head" src="../assets/img/JIS.svg" alt="JIS.svg">
            <p class="info_head_annotation long">Стандартное обозначение любого японского
              аккумулятора состоит из ряда букв и цифр.</p>
          </div>
        </div>
        <div class="info_description">
          <div class="info_description_column">
            <p>Рассмотрим, как расшифровываются значения параметров аккумулятора на примере
              маркеровки <span class="info_bold">46B24L</span></p>
          </div>
          <div class="info_description_column">
          </div>
          <div class="info_description_column">
            <p><span class="info_bold">Первая цифра «46»</span> - эксплуатационный показатель,
              характеризующий отношение между емкостью аккумулятора и работой стартера. Чем выше
              число, тем выше рабочие характеристики батареи. Это первое число может состоять из
              двух или трех цифр.</p>
          </div>
          <div class="info_description_column">
            <p><span class="info_bold">Число «24»</span> - показывает округленную до сантиметров
            длину батареи, т.е. длина аккумулятора 46B24L составляет приблизительно 24 см. на
            самом деле длина такой батареи 23,6 см.</p>
          </div>
          <div class="info_description_column">
            <div class="info_description_block">
              <p><span class="info_bold">Буква «B»</span> - показывает размеры меньшей боковой
              поверхности батареи: 127х200 мм (ширина х высота)</p>
              <p>Букв, обозначающих размеры этой поверхности, 8:</p>
            </div>
            <div class="info_table">
              <p class="info_table title">Буквенное обозначение</p>
              <p class="info_table title">Размер, мм (ШхВ)</p>
              <p class="info_table value">A</p><p class="info_table value">125x160</p>
              <p class="info_table value">B</p><p class="info_table value">127x200</p>
              <p class="info_table value">C</p><p class="info_table value">133x204</p>
              <p class="info_table value">D</p><p class="info_table value">170x200</p>
              <p class="info_table value">E</p><p class="info_table value">173x209</p>
              <p class="info_table value">F</p><p class="info_table value">180x210</p>
              <p class="info_table value">G</p><p class="info_table value">220x210</p>
              <p class="info_table value">H</p><p class="info_table value">276x216</p>
            </div>
          </div>
          <div class="info_description_column">
            <div class="info_description_block">
              <p><span class="info_bold">Буква «L»</span> - означает, что батарея с «левым»
              расположением клемм. Вместо буквы L может быть буква R. Это будет означать,
              что батарея имеет «правое» расположение клемм.</p>
              <div class="info_description_images">
                <img class="info_images_group" src="../assets/img/polarL.svg" alt="polarL.svg">
                <img class="info_images_group" src="../assets/img/polarR.svg" alt="polarR.svg">
              </div>
            </div>
            <div class="info_description_block">
              <p>Если нет никакой буквы, значит расположение клемм следующее:</p>
              <div class="info_description_images">
                <img class="info_images_group" src="../assets/img/polar.svg" alt="polar.svg">
              </div>
            </div>
            <p>Если после буквы R или L стоит буква S, то клеммы имеют специальное исполнение.</p>
          </div>
          <div class="info_description_bottom">
            <p>Клеммы бывают «толстыми», «тонкими» и специального исполнения.</p>
            <div class="info_description_clamps">
              <div class="info_clamp">
                <p><span class="info_bold">«Тонкие» клеммы</span></p>
                <img class="clamps_top" src="../assets/img/clampsThinTop.svg" alt="clampsThinTop.svg">
                <img class="clamps" src="../assets/img/clampsThin.svg" alt="clampsThin.svg">
                <p class="clamps_desc">Высота 17 мм, диаметр<br>
                плюсовой клеммы 14,7 мм,<br>минусовой – 13 мм</p>
              </div>
              <div class="info_clamp">
                <p><span class="info_bold">«Толстые» клеммы</span></p>
                <img class="clamps_top" src="../assets/img/clampsBoldTop.svg" alt="clampsBoldTop.svg">
                <img class="clamps" src="../assets/img/clampsBold.svg" alt="clampsBold.svg">
                <p class="clamps_desc">Высота 17 мм, диаметр<br>
                плюсовой клеммы 19,5 мм,<br>минусовой – 17,9 мм</p>
              </div>
              <div class="info_clamp">
                <p><span class="info_bold">Клеммы специального назначения</span>
                имеют такой вид:</p>
                <img class="clamps_spec" src="../assets/img/clampsSpec2.svg" alt="clampsSpec2.svg">
                <img class="clamps_spec" src="../assets/img/clampsSpec1.svg" alt="clampsSpec1.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ПОДБОР АККУМУЛЯТОРА EN -->
      <div v-if="currentTab === 'Подбор аккумулятора EN'">
        <div class="info_head_body long">
          <div class="info_head_content long">
            <p class="info_head_title long">Как <br>подобрать аккумулятор стандарта EN (DIN)</p>
            <img class="info_head" src="../assets/img/en.svg" alt="en.svg">
            <p class="info_head_annotation long">В наличии FURUKAWA BATTERY в РФ есть две
              серии аккумуляторов европейского стандарта EN</p>
          </div>
        </div>
        <div class="info_description">
          <p>Номера аккумуляторов стандарта EN отличаются своей структурой.</p>
          <div class="info_series_desc">
            <div class="info_description_column">
              <div class="info_description_block">
                <p class="info_series_titles">Premium Gold SMF</p>
                <p>необслуживаемые аккумуляторы увеличенной емкости</p>
              </div>
            </div>
            <div class="info_description_column">
              <div class="info_description_block">
                <p class="info_series_titles">ECHNO EN</p>
                <p>аккумуляторы по технологии EFB</p>
              </div>
            </div>
            <div class="info_typical_sizes">
              <h3 class="info_standard">Общие стандарты размеров </h3>
              <div class="info_series_desc">
                <div class="info_description_column">
                  <div class="info_description_block">
                    <p  class="info_series_titles">Типоразмеры
                    L1, L2, L3, L4, L5</p>
                    <p>Отличаться будет только длина АКБ, ширина и высота<br>
                    одинаковые для всех размеров</p>
                    <p>Высота - 190мм<br>Ширина - 175мм</p>
                  </div>
                  <div class="info_table">
                    <p class="info_table title medium">Буквенное обозначение</p>
                    <p class="info_table title medium">Размер, мм (ШхВ)</p>
                    <p class="info_table value">L1</p>
                    <p class="info_table value">257</p>
                    <p class="info_table value">L2</p>
                    <p class="info_table value">242</p>
                    <p class="info_table value">L3</p>
                    <p class="info_table value">278</p>
                    <p class="info_table value">L4</p>
                    <p class="info_table value">317</p>
                    <p class="info_table value">L5</p>
                    <p class="info_table value">353</p>
                  </div>
                </div>
                <div class="info_description_column">
                  <div class="info_description_block">
                    <p class="info_series_titles">Типоразмеры
                    LB1, LB2, LB3, LB4, LB5</p>
                    <p>Буква B означает, что высота АКБ укорочена, в
                    остальном размеры одинаковые</p>
                    <p>Высота - 175мм<br>Ширина - 175мм</p>
                  </div>
                  <div class="info_table">
                    <p class="info_table title medium">Буквенное обозначение</p>
                    <p class="info_table title medium">Размер, мм (ШхВ)</p>
                    <p class="info_table value">LB1</p>
                    <p class="info_table value">257</p>
                    <p class="info_table value">LB2</p>
                    <p class="info_table value">242</p>
                    <p class="info_table value">LB3</p>
                    <p class="info_table value">278</p>
                    <p class="info_table value">LB4</p>
                    <p class="info_table value">317</p>
                    <p class="info_table value">LB5</p>
                    <p class="info_table value">353</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Premium Gold SMF -->
        <div class="info_description extended">
          <div class="info_description_head">
            <h3 class="info_standard">Premium Gold SMF</h3>
            <p>Рассмотрим, как расшифровываются значения параметров аккумулятора на примере
            маркеровки<span class="info_bold"><br>LN2R DIN65 - SMF</span></p>
          </div>
          <div class="info_description_body">
            <div class="info_body_column">
              <div class="info_description_block">
                <p><span class="info_bold">LN2</span>
                 - показывает размеры аккумуляторной батареи.
                </p>
                <div class="info_table">
                  <p class="info_table title medium">Буквенное обозначение</p>
                  <p class="info_table title medium">Размер, мм (ШхВ)</p>
                  <p class="info_table value medium">LBN1</p>
                  <p class="info_table value medium">207x175x175</p>
                  <p class="info_table value medium">LN2</p>
                  <p class="info_table value medium">242x175x190</p>
                  <p class="info_table value medium">LN3</p>
                  <p class="info_table value medium">278x175x190</p>
                  <p class="info_table value medium">LN5</p>
                  <p class="info_table value medium">353x175x190</p>
                </div>
              </div>
              <div class="info_description_block">
                <p><span class="info_bold">Буква «R»</span> - означает, прямую полярность, где
                отрицательная клемма справа. <br><span class="info_bold">Буква «L»</span> - не
                указывается в артикуле аккумулятора (LN2 DIN65 - SMF). Означает обратную
                полярность, где отрицательная клемма находится слева.</p>
                <div class="info_description_images">
                  <img class="info_images_group" src="../assets/img/polarR.svg" alt="polarR.svg">
                  <img class="info_images_group" src="../assets/img/polarL.svg" alt="polarL.svg">
                </div>
              </div>
            </div>
            <div class="info_body_column short">
              <p><span class="info_bold">DIN
                </span> - полное соответствие немецкому стандарту.</p>
                <p><span class="info_bold">65
                </span> - емкость аккумуляторной батареии в Ah.</p>
                <p><span class="info_bold">SMF
                </span> - тип аккумулятора Super Maintenance Free, что
                означает полностью необслуживаемые батареи.</p>
            </div>
          </div>
        </div>
        <!-- ECHNO EN -->
        <div class="info_description extended">
          <div class="info_description_head">
            <h3 class="info_standard">ECHNO EN</h3>
            <div class="info_description_block">
              <p>Серия ECHNO EN производится только обратной полярности.</p>
              <div class="info_description_images">
                <img class="info_images_group" src="../assets/img/polarReverse.svg" alt="PolarReverse.svg">
                <li class="info_bold">Обратная полярность</li>
              </div>
            </div>
          </div>
          <div class="info_description_body">
            <p>Рассмотрим, как расшифровываются значения параметров аккумулятора на<br>
              примере маркеровки <br><span class="info_bold">390LN3 - IS</span></p>
          </div>
          <div class="info_description_body column">
            <div class="info_body_column">
              <div class="info_description_block">
                <p><span class="info_bold">390</span> - индекс емкости</p>
                <p>Определяется по формуле:</p>
                <div class="info_formula">
                  <img class="formula" src="../assets/img/formulaPR.svg" alt="formulaRR.svg">
                  <p class="info_formula">где, CCR - пусковой ток в амперах, 20HR - емкость<br>
                  при 20-часовом разряде.</p>
                </div>
              </div>
              <p class="desktop"><span class="info_bold">IS</span>
                - Idle Stop - стандарт Start-Stop</p>
            </div>
            <div class="info_body_column">
              <div class="info_description_block">
                <p><span class="info_bold">LN3</span>
                - показывает размеры аккумуляторной батареи.</p>
                <div class="info_table">
                  <p class="info_table title medium">Буквенное обозначение</p>
                  <p class="info_table title medium">Размер, мм (ШхВ)</p>
                  <p class="info_table value medium">LN1</p>
                  <p class="info_table value medium">207x175x190</p>
                  <p class="info_table value medium">LN2</p>
                  <p class="info_table value medium">242x175x190</p>
                  <p class="info_table value medium">LN3</p>
                  <p class="info_table value medium">278x175x190</p>
                  <p class="info_table value medium">LN4</p>
                  <p class="info_table value medium">353x175x190</p>
                </div>
                <p class="tablet"><span class="info_bold">IS</span>
                - Idle Stop - стандарт Start-Stop</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ЭКСПЛУАТАЦИЯ АККУМУЛЯТОРОВ -->
      <div v-if="currentTab === 'Эксплуатация аккумуляторов'">
        <div class="info_head_body short">
          <div class="info_head_content short">
            <p class="info_head_title short">Эксплуатация <br>аккумуляторов</p>
            <p class="info_head_annotation short">Чтобы АКБ служила долго, <br>нельзя
              допускать как <br>перезаряд, так и недозаряд батареи.</p>
          </div>
        </div>
        <div class="info_description height">
          <div class="info_description_column">
            <p>Если заряд аккумулятора куда-то уходит, причиной могут быть и
              колебания напряжения в неисправной электросети автомобиля, снижающие
              срок службы АКБ в два раза, и неправильно подобранная емкость батареи,
              из-за чего она может страдать от хронического недозаряда, так как не
              справляется стартер автомобиля, и установленное на автомобиле мощное
              электрооборудование, которое разряжает батарею быстрее, чем она успевает
              заряжаться, скажем, при обычной езде в городском режиме. Виноват может
              быть и саморазряд батареи через грязь на крышке и неочищенные клеммы. </p>
          </div>
          <div class="info_description_column">
            <p>Львиная доля проблем с батареей возникает из-за неисправности других
              узлов автомобиля. Если этого не учитывать, то даже новую батарею постигнет
              печальная участь — она будет разряжена.</p>
            <p>Если же утром стартер вращает двигатель с трудом, а заряд батареи в норме,
              в плохом пуске может быть виноват либо самстартер, либо окислившиеся провода,
              либо слабо натянутый ремень генератора.</p>
          </div>
          <p class="info_exploitation">Плотность электролита в полностью заряженном АКБ,
            который отгружается с завода компании Furukawa Battery, составляет 1,27-1,29
            г/см3 в зависимости от модели АКБ.</p>
        </div>
        <div class="info_exploitation_image">
          <img class="info_exploitation" src="../assets/img/batteryDesc.svg" alt="batteryDesc.svg">
          <p class="info_battery_desc">Дата изготовления</p>
          <p class="info_battery_desc">Сверхдолгий срок службы
            аккумуляторов в любом климете обеспечивается применением технологии BOOK-MOLD
            и CSS при изготовлении пластин. Это гарантирует надежное удержание
            активной массы на решетках.</p>
          <p class="info_battery_desc">Шкала уровня электролита</p>
        </div>
        <div class="info_exploitation_image__mobile">
          <img class="info_exploitation" src="../assets/img/batteryDesc_mobile.svg" alt="batteryDesc_mobil.svg">
          <p class="info_battery_desc">Дата изготовления</p>
          <p class="info_battery_desc">Сверхдолгий срок службы
            аккумуляторов в любом климете обеспечивается применением технологии BOOK-MOLD
            и CSS при изготовлении пластин. Это гарантирует надежное удержание
            активной массы на решетках.</p>
          <p class="info_battery_desc">Шкала уровня электролита</p>
        </div>
        <div class="info_description extended">
          <div class="info_description_head">
            <p  class="info_series_titles">Имеет значение также и возраст АКБ: обычные батареи
              редко выхаживают более 3 лет. АКБ хорошего же качества способны работать до 5—7
              лет — например, аккумуляторы FB от компании FURUKAWA.</p>
          </div>
          <div class="info_description_body">
            <div class="info_description_column">
              <p>Дата производства аккумуляторов моделей FB, за исключением модельного ряда
                PREMIUM GOLD SMF, нанесена при помощи теснения на информационный стикер, который
                находится на крышке аккумулятора. Место нанесения даты на стикере может меняться
                в зависимости от модели.</p>
            </div>
            <div class="info_description_column extended">
              <p>Дата производства имеет формат «дд.мм.гг» (день.месяц.год). Латинская буква,
                находящаяся в конце даты производства, обозначает производственную линию
                (не несёт в себе потребительской информации).</p>
            </div>
          </div>
          <div class="info_description_bottom" style="margin-bottom: 0px; margin-top: 20px">
            <p>Дата производства аккумуляторов модельного ряда PREMIUM GOLD SMF нанесена на
              крышке аккумулятора (выдавлена на пластмассе) и имеет
              буквенно-цифровое обозначение. Например, формат даты производства
              следующий: <span class="info_bold">03UJA</span></p>
              <p>Первые цифры – это число месяца производства</p>
              <div class="info_description_block">
                <p>Буква, идущая за цифрами – месяц производства</p>
                <div class="info_table tablet">
                  <p class="info_table small">B</p><p class="info_table small">Январь</p>
                  <p class="info_table small">E</p><p class="info_table small">Февраль</p>
                  <p class="info_table small">S</p><p class="info_table small">Март</p>
                  <p class="info_table small">T</p><p class="info_table small">Апрель</p>
                  <p class="info_table small">I</p><p class="info_table small">Май</p>
                  <p class="info_table small">N</p><p class="info_table small">Июнь</p>
                  <p class="info_table small">O</p><p class="info_table small">Июль</p>
                  <p class="info_table small">U</p><p class="info_table small">Август</p>
                  <p class="info_table small">R</p><p class="info_table small">Сентябрь</p>
                  <p class="info_table small">D</p><p class="info_table small">Октябрь</p>
                  <p class="info_table small">A</p><p class="info_table small">Ноябрь</p>
                  <p class="info_table small">Y</p><p class="info_table small">Декабрь</p>
                </div>
                <div class="info_table__mobile">
                  <div class="info_table medium">
                    <p class="info_table small">B</p><p class="info_table small">Январь</p>
                    <p class="info_table small">E</p><p class="info_table small">Февраль</p>
                    <p class="info_table small">S</p><p class="info_table small">Март</p>
                    <p class="info_table small">T</p><p class="info_table small">Апрель</p>
                    <p class="info_table small">I</p><p class="info_table small">Май</p>
                    <p class="info_table small">N</p><p class="info_table small">Июнь</p>
                  </div>
                  <div class="info_table medium">
                    <p class="info_table small">O</p><p class="info_table small">Июль</p>
                    <p class="info_table small">U</p><p class="info_table small">Август</p>
                    <p class="info_table small">R</p><p class="info_table small">Сентябрь</p>
                    <p class="info_table small">D</p><p class="info_table small">Октябрь</p>
                    <p class="info_table small">A</p><p class="info_table small">Ноябрь</p>
                    <p class="info_table small">Y</p><p class="info_table small">Декабрь</p>
                  </div>
                </div>
              </div>
              <div class="info_description_block">
                <p>Вторая буква – год даты производства</p>
                <div class="info_table tablet" style="height: 62px">
                  <p class="info_table small years">D</p><p class="info_table small years">2014</p>
                  <p class="info_table small years">E</p><p class="info_table small years">2015</p>
                  <p class="info_table small years">F</p><p class="info_table small years">2016</p>
                  <p class="info_table small years">G</p><p class="info_table small years">2017</p>
                  <p class="info_table small years">H</p><p class="info_table small years">2018</p>
                  <p class="info_table small years">I</p><p class="info_table small years">2019</p>
                  <p class="info_table small years">J</p><p class="info_table small years">2020</p>
                  <p class="info_table small years">K</p><p class="info_table small years">2021</p>
                  <p class="info_table small years">L</p><p class="info_table small years">2022</p>
                  <p class="info_table small years">M</p><p class="info_table small years">2023</p>
                  <p class="info_table small years">N</p><p class="info_table small years">2024</p>
                  <p class="info_table small years">O</p><p class="info_table small years">2025</p>
                </div>
                <div class="info_table__mobile">
                  <div class="info_table small">
                    <p class="info_table small years">D</p>
                    <p class="info_table small years">2014</p>
                    <p class="info_table small years">E</p>
                    <p class="info_table small years">2015</p>
                    <p class="info_table small years">F</p>
                    <p class="info_table small years">2016</p>
                    <p class="info_table small years">G</p>
                    <p class="info_table small years">2017</p>
                    <p class="info_table small years">H</p>
                    <p class="info_table small years">2018</p>
                    <p class="info_table small years">I</p>
                    <p class="info_table small years">2019</p>
                  </div>
                  <div class="info_table small">
                    <p class="info_table small years">J</p>
                    <p class="info_table small years">2020</p>
                    <p class="info_table small years">K</p>
                    <p class="info_table small years">2021</p>
                    <p class="info_table small years">L</p>
                    <p class="info_table small years">2022</p>
                    <p class="info_table small years">M</p>
                    <p class="info_table small years">2023</p>
                    <p class="info_table small years">N</p>
                    <p class="info_table small years">2024</p>
                    <p class="info_table small years">O</p>
                    <p class="info_table small years">2025</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- ВЫБОР БАТАРЕИ -->
      <div v-if="currentTab === 'Выбор батареи'">
        <div class="info_head_body short">
          <div class="info_head_content medium">
            <p class="info_head_title short">Какую батарею выбрать</p>
            <p class="info_head_annotation short">При подборе АКБ по данным таблицам,
              гарантийная программа на батарею не распространяется </p>
          </div>
        </div>
        <div class="info_description">
          <p class="info_series_titles">Таблицы оптимального соотношения объема
          двигателя и емкости аккумуляторной батареи (для стандартных батарей)</p>
          <div class="info_description_block">
            <p>Бензиновые карбюраторные двигатели</p>
            <div class="info_table mobile">
              <p class="info_table title fixed">
                Рабочий объем <br>двигателя, куб. дм.</p>
              <p class="info_table title fixed">
                Емкость аккумуляторной<br>батареи, Ач</p>
              <p class="info_table value fixed">менее 1,2</p>
              <p class="info_table value fixed">44</p>
              <p class="info_table value fixed">1,2 - 1,8</p>
              <p class="info_table value fixed">55</p>
              <p class="info_table value fixed">1,8 - 2,5</p>
              <p class="info_table value fixed">62 - 66</p>
              <p class="info_table value fixed">2,5 - 4,5</p>
              <p class="info_table value fixed">75</p>
              <p class="info_table value fixed">4,5 - 6,2</p>
              <p class="info_table value fixed">90</p>
              <p class="info_table value fixed">6,2 - 8,0</p>
              <p class="info_table value fixed">132</p>
            </div>
          </div>
          <div class="info_description_block">
            <p>Бензиновые двигатели с впрыском топлива</p>
            <div class="info_table mobile">
              <p class="info_table title fixed">
                Рабочий объем <br>двигателя, куб. дм.</p>
              <p class="info_table title fixed">
                Емкость аккумуляторной<br>батареи, Ач</p>
              <p class="info_table value fixed">менее 1,6</p>
              <p class="info_table value fixed">44</p>
              <p class="info_table value fixed">1,6 - 2,5</p>
              <p class="info_table value fixed">55</p>
              <p class="info_table value fixed">2,5 - 3,0</p>
              <p class="info_table value fixed">62</p>
              <p class="info_table value fixed">3,0 - 3,5</p>
              <p class="info_table value fixed">75</p>
              <p class="info_table value fixed">более 3,5</p>
              <p class="info_table value fixed">90 и более</p>
            </div>
          </div>
          <div class="info_description_block">
            <p>Дизельные двигатели</p>
            <div class="info_table mobile">
              <p class="info_table title fixed">
                Рабочий объем <br>двигателя, куб. дм.</p>
              <p class="info_table title fixed">
                Емкость аккумуляторной<br>батареи, Ач</p>
              <p class="info_table value fixed">менее 1,5</p>
              <p class="info_table value fixed">55</p>
              <p class="info_table value fixed">1,5 - 2,0</p>
              <p class="info_table value fixed">62</p>
              <p class="info_table value fixed">2,0 - 2,7</p>
              <p class="info_table value fixed">75</p>
              <p class="info_table value fixed">2,7 - 3,5</p>
              <p class="info_table value fixed">90</p>
              <p class="info_table value fixed">3,5 - 6,5</p>
              <p class="info_table value fixed">132</p>
              <p class="info_table value fixed">более 6,5</p>
              <p class="info_table value fixed">190 и более</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel';

export default {
  components: { carousel },
  data() {
    return {
      currentTab: 'Подбор аккумулятора JIS',
      navigationTabs: ['Подбор аккумулятора JIS', 'Подбор аккумулятора EN', 'Эксплуатация аккумуляторов', 'Выбор батареи'],
    };
  },
  methods: {
    openPage(link) {
      this.$root.$emit('openPage', link);
    },
  },
  metaInfo: {
    title: 'Техническая информация',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
};
</script>
