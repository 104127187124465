<template>
    <div class='main_catalog'>
          <div
            class="carousel_catalog"
            v-if="CatalogTabs">
            <carousel :items="1" :nav="false" :dots="false"
              :margin="24" :autoWidth="true" :autoHeight="true"
            >
              <button
                class='main_catalog_nav'
                :class="{ active: SelectedTab === tab.title }"
                v-for="(tab, index) in CatalogTabs"
                :key="index"
                @click="SelectedTab = tab.title; updateBattariesList()"
              >
                  {{tab.title}}
              </button>
              <a href="/catalog">
                <button class='main_catalog_nav catalog'>Каталог</button>
              </a>
              <button class="button__visibility_hidden"></button>
            </carousel>
          </div>
          <div class='main_catalog_nav'>
            <button
              class='main_catalog_nav'
              :class="{ active: SelectedTab === tab.title }"
              v-for="(tab, index) in CatalogTabs"
              :key="index"
              @click="SelectedTab = tab.title; updateBattariesList()"
            >
              {{tab.title}}
            </button>
            <a href="/catalog">
              <button class='main_catalog_nav catalog'>Каталог</button>
            </a>
          </div>
          <div class="carousel_batteries" v-if="Catalog">
            <carousel :key="batteriesRefreshKey" :items="2" :nav="false" :dots="false"
              :margin="24" :autoWidth="true" :autoHeight="true">
              <div
                class='main_catalog_body_item'
                v-for="(battery, index) in batteriesList"
                @click="openCatalogPage(SelectedTab, battery.title)"
                :key="index"
              >
                <div class="main_catalog_body_item_block">
                    <div class="main_catalog_image">
                        <img
                          class="main_catalog"
                          :src="getStaticImage('categories', battery.filename)"
                          :alt="battery.filename"/>
                    </div>
                </div>
                <div class="main_catalog_body_item_block">
                    <div class="main_catalog_body_item_title">
                        <h3 class="main_catalog_title">
                            {{battery.title}}
                        </h3>
                    </div>
                    <div class="main_catalog_body_item_info">
                        <p class="main_catalog_info">
                            {{battery.description}}
                        </p>
                    </div>
                    <div class="main_catalog_body_item_suitable">
                        <img
                          class="catalog_suitable"
                          v-for="(item, index) in battery.properties"
                          :key="index"
                          :src="getStaticImage('icons', item.icon)"
                          :alt="item.icon"/>
                    </div>
                </div>
              </div>
            </carousel>
          </div>
          <div
            class='main_catalog_body'
            v-if="Catalog"
          >
            <div
              class='main_catalog_body_item'
              v-for="(battery, index) in batteriesList"
              @click="openCatalogPage(SelectedTab, battery.title)"
              :key="index"
            >
              <div class="main_catalog_body_item_block">
                  <div class="main_catalog_image">
                      <img
                        class="main_catalog"
                        :src="getStaticImage('categories', battery.filename)"
                        :alt="battery.filename"/>
                  </div>
              </div>
              <div class="main_catalog_body_item_block">
                  <div class="main_catalog_body_item_title">
                      <h3 class="main_catalog_title">
                         {{battery.title}}
                      </h3>
                  </div>
                  <div class="main_catalog_body_item_info">
                      <p class="main_catalog_info">
                          {{battery.description}}
                      </p>
                  </div>
                  <div class="main_catalog_body_item_suitable">
                      <img
                        class="catalog_suitable"
                        v-for="(item, index) in battery.properties"
                        :key="index"
                        :src="getStaticImage('icons', item.icon)"
                        :alt="item.icon"/>
                  </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import utils from '../utils';

export default {
  components: { carousel },
  name: 'main-catalog',
  data() {
    return {
      Catalog: null,
      CatalogTabs: null,
      SelectedTab: null,
      batteriesRefreshKey: 0,
      batteriesList: [],
    };
  },
  methods: {
    openCatalogPage(series, category) {
      const tab = {
        path: '/catalog',
        value: { tab: series, item: category },
      };
      this.$store.commit('setTab', tab);
      this.$router.push({ path: tab.path });
    },
    updateBattariesList() {
      this.batteriesList = this.Catalog.filter((el) => el.stitle === this.SelectedTab);
      this.batteriesRefreshKey += 1;
    },
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
    getStaticImage(type, name) {
      const { getStaticImage } = utils();
      return getStaticImage(type, name);
    },
  },
  async beforeMount() {
    let responce = await this.fetchData('get', 'series');
    if (responce) {
      this.CatalogTabs = responce.data;
      this.SelectedTab = this.CatalogTabs[0].title;
    }
    responce = await this.fetchData('get', 'categories');
    this.Catalog = responce.data ? responce.data : null;
    this.updateBattariesList();
  },
};
</script>
