<template>
  <div class="map">
    <div class="assurance_body map">
      <div class="map_navigation">
        <div class="map_navigation_selection">
          <!-- <select class="uk-select map" @change="onChange($event)">
            <option selected disabled hidden>Выберите город</option>
            <option
                v-for="(city, index) in citiesList"
                :key="index"
                :value="city.id"
                :selected="selectedCity === city.name">
              {{ city.name }}
            </option>
          </select> -->

          <div class="selection-item col-5 cities">
            <input
                autocomplete="new-password"
                class="uk-input map_selector cities"
                placeholder="Выберите город"
                data-index="cities"
                @keyup="insertedSearchFilter($event, 'cities')"
                @focus="$event.target.nextSibling.classList.add('is-active')"
                @blur="
                        $event.target.nextSibling.matches(':hover') ? '' :
                        $event.target.nextSibling.classList.remove('is-active')
                      "
                @keypress.enter="autoInsertedSearchFilter($event)"
                id = 'input-def'
            />
            <div class="selection-item__list">
              <div v-for="(city, index) in citiesList"
                   :key="index"
                   v-show="showBySearch('cities', city)"
                   :data-value="city.id"
                   @click="onChange($event)"
                   class="selection-item__list-item"
              >
                {{ city.name }}
              </div>
            </div>
          </div>
          <img
              class="location"
              src="../assets/img/location.png"
              alt="location.png"
              @click="getLocation()"
          />
        </div>
        <div v-if="spinner && !buildings" class="map_center map_flex">
          <div class="loader-box-map loader-box-flex">
            <div class="loading loading-item-bg"></div>
            <div class="loading loading-item-md"></div>
            <div class="loading loading-item-md"></div>
            <div class="loading loading-item-bg"></div>
          </div>
          <div class="loader-box-map loader-box-flex">
            <div class="loading loading-item-bg"></div>
            <div class="loading loading-item-md"></div>
            <div class="loading loading-item-md"></div>
            <div class="loading loading-item-bg"></div>
          </div>
          <div class="loader-box-map loader-box-flex">
            <div class="loading loading-item-bg"></div>
            <div class="loading loading-item-md"></div>
            <div class="loading loading-item-md"></div>
            <div class="loading loading-item-bg"></div>
          </div>
        </div>
        <template v-if="buildings">
          <div class="map_center"
               v-for="(center, index) in buildings"
               :key="index">

            <p class="map_type" v-if="center.segment">{{ center.segment  }}</p>
            <p class="map_type" v-else-if="center.type">{{ center.type }}</p>

            <p class="map_title" v-if="center.signboard">{{ center.signboard }}</p>
            <p class="map_title" v-else>{{ center.city }}</p>

            <button
                class="map"
                :class="{selected: selectedMarker === center.coords}"
                @click="selectMarker(center.coords)">
              На карте
            </button>
            <p v-if="center.working_time">Часы работы:</p>
            <div class="map_row" v-if="center.working_time">
              {{ center.working_time }}
            </div>
            <p v-if="center.openingHours">Часы работы:</p>
            <div class="map_row" v-if="center.openingHours">
              <p v-if="openedCheck(center.openingHours).indexOf('Закрывается') !== -1">
                <span class="map_open">Открыто</span> ⋅
              </p>
              <p v-if="openedCheck(center.openingHours).indexOf('Открывается') !== -1">
                <span class="map_close">Закрыто</span> ⋅
              </p>
              <select class="map_list">
                <option selected disabled hidden>
                  {{ openedCheck(center.openingHours )}}
                </option>
                <option
                    disabled
                    v-for="(day, index) in openingList(center.openingHours)"
                    :key=index>
                  {{ day }}
                </option>
              </select>
            </div>
            <div class="map_row" v-if="center.phone">
              <p>Телефон:</p>
              <div v-if="Array.isArray(center.phone)">
                <p
                    v-for="(phone, index) in center.phone"
                    :key=index>
                  {{ phone }}
                </p>
              </div>
              <div v-else>
                <p>{{ center.phone }}</p>
              </div>
            </div>
            <div class="map_row" v-if="center.email">
              <p>E-mail:</p>
              <div v-if="Array.isArray(center.email)">
                <p  v-for="(email, index) in center.email"
                    :key=index>
                  {{ email }}
                </p>
              </div>
              <div v-else>
                <p>{{ center.email }}</p>
              </div>
            </div>
            <div class="map_row" v-if="center.url">
              <p>сайт:</p>
              <div>
                <p>
                  <a :href="center.url">{{ center.url }}</a>
                </p>
              </div>
            </div>
            <p class="map_address">{{ center.address }}</p>
          </div>
        </template>
      </div>
      <yandex-map
          :settings="settings"
          :coords="coords"
          :zoom="zoom || 15"
          :controls="controls"
          ref="ymap"
      >
        <ymap-marker
            v-for="(center, index) in buildings"
            :key="index"
            :marker-id="index"
            :coords="center.coords"
            :hint-content="center.signboard || center.address"
            :icon="selectedMarker === center.coords ? selectedMarkerIcon : markerIcon"
            :balloon-template="center.balloon"
        ></ymap-marker>
      </yandex-map>
    </div>
    <div class="assurance_body map mobile">
      <div class="navigator-map">
        <button
            class="map navigator"
            :class="{ active: mobileCurrentTab === tab }"
            v-for="(tab, index) in mobileTabs"
            :key="index"
            @click="(mobileCurrentTab = tab)"
        >
          {{tab}}
        </button>
      </div>
      <div class="map_navigation_selection">
        <div class="selection-item col-5 cities">
          <input
              autocomplete="new-password"
              class="uk-input map_selector cities"
              placeholder="Выберите город"
              data-index="cities"
              @keyup="insertedSearchFilter($event, 'cities')"
              @focus="$event.target.nextSibling.classList.add('is-active')"
              @blur="
                      $event.target.nextSibling.matches(':hover') ? '' :
                      $event.target.nextSibling.classList.remove('is-active')
                    "
              @keypress.enter="autoInsertedSearchFilter($event)"
              id = 'input-mob'
          />
          <div class="selection-item__list">
            <div v-for="(city, index) in citiesList"
                  :key="index"
                  v-show="showBySearch('cities', city)"
                  :data-value="city.id"
                  @click="onChange($event)"
                  class="selection-item__list-item"
            >
              {{ city.name }}
            </div>
          </div>
          </div>
        <img
            class="location"
            src="../assets/img/location.png"
            alt="location.png"
            @click="getLocation(true)"
        />
      </div>
      <div
        class="body-list__mobile"
        v-show="mobileCurrentTab === 'Списком'"
      >
        <div v-if="spinner && !buildings" class="map_center map_center_full">
          <div uk-spinner></div>
        </div>
        <template v-if="buildings">
          <div class="map_center"
              v-for="(center, index) in buildings"
              :key="index">

            <p class="map_type" v-if="center.segment">{{ center.segment  }}</p>
            <p class="map_type" v-else>{{ 'Офис компании' }}</p>

            <p class="map_title" v-if="center.signboard">{{ center.signboard }}</p>
            <p class="map_title" v-else>{{ center.city }}</p>

            <button
                class="map"
                :class="{selected: selectedMarker === center.address}"
                @click="(mobileCurrentTab = 'На карте') && (selectMarker(center.coords))">
              На карте
            </button>
            <p v-if="center.openingHours">Часы работы:</p>
            <div class="map_row"
                v-if="center.openingHours">
              <p
                  v-if="openedCheck(center.openingHours).indexOf('Закрывается') !== -1">
                <span class="map_open">Открыто</span> ⋅
              </p>
              <p
                  v-if="openedCheck(center.openingHours).indexOf('Открывается') !== -1">
                <span class="map_close">Закрыто</span> ⋅
              </p>
              <select class="map_list">
                <option
                    selected
                    disabled
                    hidden>
                  {{ openedCheck(center.openingHours )}}
                </option>
                <option
                    disabled
                    v-for="(day, index) in openingList(center.openingHours)"
                    :key="index">{{day}}
                </option>
              </select>
            </div>
            <div class="map_row" v-if="center.phone">
              <p>Телефон:</p>
              <div v-if="Array.isArray(center.phone)">
                <p
                    v-for="(phone, index) in center.phone"
                    :key=index>
                  {{ phone }}
                </p>
              </div>
            </div>
            <div class="map_row" v-if="center.email">
              <p>E-mail:</p>
              <div v-if="Array.isArray(center.email)">
                <p  v-for="(email, index) in center.email"
                    :key=index>
                  {{ email }}
                </p>
              </div>
              <div v-else>
                <p>{{ center.email }}</p>
              </div>
            </div>
            <div class="map_row"
                v-if="center.url">
              <p>сайт:</p>
              <div>
                <p>
                  <a :href="center.url">{{ center.url }}</a>
                </p>
              </div>
            </div>
            <p class="map_address">{{ center.address }}</p>
          </div>
        </template>
      </div>
      <yandex-map
        v-if="mobileCurrentTab === 'На карте'"
        :settings="settings"
        :coords="coords"
        :zoom="zoom || 15"
        :controls="controls"
        ref="ymap"
      >
        <ymap-marker
          v-for="(center, index) in buildings"
          :key="index"
          :marker-id="index"
          :coords="center.coords"
          :hint-content="center.signboard || center.address"
          :icon="selectedMarker === center.coords ? selectedMarkerIcon : markerIcon"
          :balloon-template="center.balloon"
        ></ymap-marker>
      </yandex-map>
    </div>
    <div id="popup-map" class="modal-popup">
      <div class="popup">
        <span class='close blue popup'
              @click="$event.target.closest('.modal-popup').style.display = 'none'">&times;</span>
        <p popup-text>Не удалось определить местоположение</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import utils from '../utils';

export default {
  components: { yandexMap, ymapMarker },
  props: {
    data: null,
    api_cities: {
      default: 'cities/fb',
    },
    api_points: {
      default: 'points/fb',
    },
  },
  data() {
    return {
      myMap: '',
      zoom: null,
      selectedMarker: '',
      mobileCurrentTab: 'Списком',
      mobileTabs: ['Списком', 'На карте'],
      spinner: false,
      citiesList: null,
      selectedCity: null,
      buildings: null,
      SelectedFilters: [],
      SelectedSearch: [],
      controls: ['zoomControl', 'typeSelector'],
      coords: [43.123156, 131.909521],
      settings: {
        apiKey: 'f317e91a-90e1-4fa0-8f6f-11039c4c4f6d',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      markerIcon: {
        layout: 'default#image',
        imageHref: '/img/WmNM8rR.png', // 'https://i.imgur.com/WmNM8rR.png',
        imageSize: [62, 56],
        imageOffset: [-31, -56],
      },
      selectedMarkerIcon: {
        layout: 'default#image',
        imageHref: '/img/WmNM8rRS.png',
        imageSize: [62, 56],
        imageOffset: [-31, -56],
      },
    };
  },
  methods: {
    choiceInput(event) {
      const parent = event.target.closest('.selection-item');
      const input = parent.querySelector('input');
      parent.querySelector('.selection-item__list').classList.toggle('is-active');
      input.value = event.target.innerText;
    },
    showBySearch(name, value) {
      const search = this.SelectedSearch[name];
      if (!search || search.length < 2) return true;
      return (value.name && value.name.toLowerCase().includes(search.toLowerCase()));
    },
    autoInsertedSearchFilter(event) {
      const parent = event.target.closest('.selection-item');
      const list = parent.querySelectorAll('.selection-item__list-item');
      const BreakException = {};
      try {
        list.forEach((item) => {
          const text = item.innerText.toLowerCase();
          if (text.includes(event.target.value.toLowerCase())) {
            item.click();
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    insertedSearchFilter(event, name) {
      this.SelectedSearch[name] = event.target.value;
      delete this.SelectedFilters[name];
      this.$forceUpdate();
    },
    selectMarker(coords) {
      this.selectedMarker = coords;
      this.coords = coords;
    },
    async onChange(event) {
      this.choiceInput(event);
      this.buildings = null;
      this.spinner = true;
      this.selectedCity = event.target.dataset.value;
      await this.getPoints(event.target.dataset.value);
      this.getAvgPoints(this.buildings);
      // this.fetchDataLocationByCity(event.target.innerText);
    },
    async getPoints(id) {
      const target = `${this.api_points}/${id}`;
      const response = await this.fetchData('get', target);
      const temp = response;
      for (let i = 0; i < response.length; i += 1) {
        if (!temp[i].coords) {
          temp[i].coords = [
            temp[i].address.latitude,
            temp[i].address.longitude,
          ];
          const { icon } = temp[i];
          delete temp[i].icon;
          temp[i].icon = { content: icon };
          temp[i].address = temp[i].address.name;
        }
      }
      this.buildings = response;
    },
    getAvgPoints(buildings) {
      const len = buildings.length;
      let lat = 0;
      let lng = 0;
      for (let i = 0; i < len; i += 1) {
        lat += +buildings[i].coords[0];
        lng += +buildings[i].coords[1];
      }
      this.coords = [
        lat / len,
        lng / len,
      ];
    },
    openingList(h) {
      const openedHours = [];
      for (let i = 1; i <= 6; i += 1) {
        const currentDay = h[i];
        if (!currentDay.opens) {
          openedHours.push(`${currentDay.day}: закрыто`);
        } else {
          openedHours.push(`${currentDay.day}: ${currentDay.opens}:00 - ${currentDay.closes}:00`);
        }
      }
      if (!h[0].opens) {
        openedHours.push(`${h[0].day}: закрыто`);
      } else {
        openedHours.push(`${h[0].day}: ${h[0].opens}:00 - ${h[0].closes}:00`);
      }
      return openedHours;
    },
    openedCheck(h) {
      const date = new Date();
      const currentDay = date.getDay();
      const openedHours = h[currentDay];
      if (openedHours.opens <= date.getHours()
          && openedHours.closes > date.getHours()) { return `Закрывается в ${openedHours.closes}:00`; }
      let i = currentDay + 1;
      for (i; h[i].opens.length > 0; i += 1) {
        if (i > 6) { i = 0; }
      }
      return `Открывается в ${h[i].opens}:00`;
    },
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
    async fetchDataLocation(lat, lng) {
      const target = `apikey=${process.env.VUE_APP_YANDEX_API_KEY}&geocode=${lng},${lat}`;
      let response;
      await axios
        .get(`${process.env.VUE_APP_YANDEX_GEO_URL}&${target}`)
        .then((res) => {
          response = res.data;
        });
      this.getCityId(response);
    },
    async fetchDataLocationByCity(city) {
      const target = `apikey=${process.env.VUE_APP_YANDEX_API_KEY}&geocode=${city}`;
      let response;
      await axios
        .get(`${process.env.VUE_APP_YANDEX_GEO_URL}&${target}`)
        .then((res) => {
          response = res.data;
        });
      this.getCityCoords(response);
    },
    async getLocation(mobile = false) {
      if (mobile) this.mobileCurrentTab = 'На карте';
      this.buildings = null;
      this.spinner = true;
      if (navigator.geolocation) {
        let lat = null;
        let lng = null;
        try {
          const getCoords = async () => {
            const position = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            return position;
          };
          const position = await getCoords();
          if (position) {
            lat = position.coords.latitude;
            lng = position.coords.longitude;
            await this.fetchDataLocation(lat, lng);
            this.coords = [lat, lng];
          } else {
            throw new Error('Не удалось определить местоположение');
          }
        } catch (e) {
          console.log(e);
          this.activatePopup('Доступ к местоположению был заблокирован настройками браузера или блокировщиком рекламы');
        }
      } else {
        console.log('Geolocation service is not supported by this browser');
        this.activatePopup('Служба геолокации не поддерживается этим браузером');
      }
    },
    activatePopup(text) {
      const popup = document.querySelector('#popup-map');
      popup.querySelector('[popup-text]').innerHTML = text;
      popup.style.display = 'flex';
      setTimeout(() => { this.spinner = false; }, 500);
    },
    getCityId(response) {
      let id = null;
      let data = null;
      // let geoCity = undefined;
      const BreakException = {};
      try {
        data = response.response.GeoObjectCollection.featureMember;
        this.citiesList.forEach((city) => {
          data.forEach((item) => {
            // let geoCity = item.GeoObject.name;
            if (city.name === item.GeoObject.name) {
              id = city.id;
              this.selectedCity = city.name;
              document.getElementById('input-def').value = city.name;
              document.getElementById('input-mob').value = city.name;
              throw BreakException;
            }
          });
        });
      } catch (e) {
        if (e !== BreakException) console.log(e);
      }
      if (id) {
        this.getPoints(id);
        setTimeout(() => { this.spinner = false; }, 500);
      } else {
        this.activatePopup('В вашем городе еще нет гарантийных центров');
      }
    },
    getCityCoords(response) {
      const BreakException = {};
      try {
        const data = response.response.GeoObjectCollection.featureMember[0];
        if (data) {
          this.coords = data.GeoObject.Point.pos.split(' ').reverse();
          throw BreakException;
        }
        setTimeout(() => { this.spinner = false; }, 500);
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
  },
  async beforeMount() {
    if (this.data) {
      this.buildings = this.data;
      this.zoom = 4;
      this.coords = [
        56.2951, 84.5827,
      ];
      window.scrollTo(0, 0);
    }

    const response = await this.fetchData('get', this.api_cities);
    this.citiesList = response || null;
  },
};
</script>
