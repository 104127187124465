import Vue from 'vue';
import Router from 'vue-router';

// IMPORT PAGES
import Main from './components/PageMain.vue';
import Selection from './components/PageSelection.vue';
import Catalog from './components/PageCatalog.vue';
import About from './components/PageAbout.vue';
import Info from './components/PageInfo.vue';
import Assurance from './components/PageAssurance.vue';
import Map from './components/PageMap.vue';
import Partners from './components/PagePartners.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'main', component: Main },
    { path: '/catalog/selection', name: 'selection', component: Selection },
    { path: '/catalog', name: 'catalog', component: Catalog },
    { path: '/about', name: 'about', component: About },
    { path: '/info', name: 'info', component: Info },
    { path: '/assurance', name: 'assurance', component: Assurance },
    { path: '/map', name: 'map', component: Map },
    { path: '/partners', name: 'partners', component: Partners },
  ],
  /* eslint-disable */
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  /* eslint-enabled */
});
