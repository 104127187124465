<template>
  <div id='app'>
    <div>
      <the-header></the-header>
      <router-view id="body" />
      <the-footer></the-footer>
    </div>
    <Metrics />
  </div>
</template>

<script>
import TheFooter from './components/TheFooter.vue';
import TheHeader from './components/TheHeader.vue';
import Metrics from './components/Metrics.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    Metrics,
  },
};
</script>

<style lang='scss'>
  @import './assets/sass/main';
</style>
