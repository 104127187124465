import axios from 'axios';

export default function () {
  const getStaticImage = (type, name) => `${process.env.VUE_APP_SERVICES_STATIC_URL}/${type}/${name}`;

  const isObjectEmpty = (obj) => obj === 'undefined' || obj === null;

  const fetchData = async (action, target) => {
    let content = [];
    try {
      await axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/${action}/${target}`)
        .then((response) => {
          content = response.data;
        });
    } catch (e) {
      console.log('We have the error', e);
    }
    return content;
  };

  return { getStaticImage, isObjectEmpty, fetchData };
}
