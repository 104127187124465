import Vue from 'vue';
import Vuex from 'vuex';
import VueMeta from 'vue-meta';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import App from './App.vue';
import router from './router';

Vue.use(Vuex);
Vue.use(VueReCaptcha, { siteKey: `${process.env.VUE_APP_CAPTCHA_SITE_KEY}` });
Vue.config.productionTip = false;
Vue.use(VueMeta);

const store = new Vuex.Store({
  state: {
    page: {
      tab: null,
      item: null,
    },
    query: null,
    filters: {
      car: [],
      params: [],
    },
  },
  mutations: {
    setTab(state, payload) {
      state.page[payload.path] = payload.value;
    },
    clearPage(state) {
      Object.keys(state.page).forEach((key) => {
        state.page[key] = null;
      });
    },
    setQuery(state, payload) {
      state.query = payload;
    },
    addFilter(state, payload) {
      if (payload.type === 'car') state.filters.car.push(payload.data);
      if (payload.type === 'params') state.filters.params.push(payload.data);
    },
    clearFilters(state) {
      state.filters.car = [];
      state.filters.params = [];
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
