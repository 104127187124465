<template>
  <div>
    <!-- eslint-disable -->
    <div id="header" class="header_default">
      <div class="header__background_white">
        <div id="header-logo"
          class='header_logo'
          :class="{small : this.$route.name !== 'main'}"
        >
          <a href="/">
            <img id="header-img"
              class='header_logo'
              :class="{small : this.$route.name !== 'main'}"
              src='../assets/FB_logo_header.svg'
              alt="header_logo">
          </a>
        </div>
        <header>
          <ul class='header'>
            <a
              class="nav_item"
              :class="{ selected: this.$route.path === '/catalog'}"
              @click="openPage('/catalog')"
            >
              <li class='nav_item'>Каталог</li>
            </a>
            <a
              class="nav_item"
              :class="{ selected: this.$route.path === '/about'}"
              @click="openPage('/about')"
            >
              <li class='nav_item'>О компании</li></a>
            <a
              class="nav_item"
              :class="{ selected: this.$route.path === '/info'}"
              @click="openPage('/info')"
            >
              <li class='nav_item'>Техническая информация</li>
            </a>
            <a
              class="nav_item"
              :class="{ selected: this.$route.path === '/assurance'}"
              @click="openPage('/assurance')"
            >
              <li class='nav_item'>Гарантия</li></a>
            <a
              class="nav_item"
              :class="{ selected: this.$route.path === '/map'}"
              @click="openPage('/map')"
            >
              <li class='nav_item'>Где купить</li>
            </a>
            <a
              class="nav_item"
              :class="{ selected: this.$route.path === '/partners'}"
              @click="openPage('/partners')"
            >
              <li class='nav_item'>Партнерам</li>
            </a>
          </ul>
        </header>
      </div>
      <div class="header-selector__tablet">
        <div
            class="header-text__tablet"
            @click="(SelectionPressed = true)
                  && (CurrentSelectorTab = 'По автомобилю')"
            :class="{
                    active: (CurrentSelectorTab === 'По автомобилю') && SelectionPressed
                  }"
        >
          <p>Подбор аккумулятора по автомобилю</p>
          <img v-show="!SelectionPressed" class="selection" src='../assets/img/down.svg' alt="down.svg">
        </div>
        <div
            class="header-text__tablet"
             @click="(SelectionPressed = true)
                    && (CurrentSelectorTab = 'По параметрам')"
             :class="{
                      active: (CurrentSelectorTab === 'По параметрам') && SelectionPressed
                    }"
        >
          <p>Подбор аккумулятора по параметрам</p>
          <img v-show="!SelectionPressed"  class="selection" src='../assets/img/down.svg' alt="down.svg">
        </div>
      </div>
      <div class="header-body__tablet"
           v-show="SelectionPressed"
      >
        <div class="selection-group selection-flex">
          <div
              class="selection-item col-5"
              v-for="(item, ind1) in SelectorsList"
              v-show="item.tab === CurrentSelectorTab"
              :key="ind1"
          >
            <input
                autocomplete="new-password"
                class="uk-input main_selector"
                :placeholder="item.title"
                :data-index="`tablet-${ind1}`"
                @keyup="insertedSearchFilter($event, item.name)"
                @focus="$event.target.nextSibling.classList.add('is-active')"
                @blur="
                        $event.target.nextSibling.matches(':hover') ? '' :
                        $event.target.nextSibling.classList.remove('is-active')
                      "
                @keypress.enter="autoInsertedSearchFilter($event)"
                :data-value="item.variants && checkFilter ? insertedInputValue(item, `tablet-${ind1}`) : true"
                :disabled="!item.variants.length"
                :id="`tablet-${item.name}`"
            />
            <div class="selection-item__list">
              <div v-if="variant.title || variant.properties"
                   v-for="(variant, ind2) in item.variants"
                   v-show="showBySearch(item.name, variant)"
                   :key="ind2"
                   :data-value="variant.id || variant.title"
                   @click="onClickFetchData($event, ind1, item.name)"
                   class="selection-item__list-item">{{ variant.title || variant.properties }}</div>
            </div>
          </div>
        </div>
        <div class="selection_group_button">
          <button
              class="selection_button"
              :class="{ unavaliable: !showButton }"
              @click="showButton ? applyFilterParams() : popupToggle('flex')"
          >
            Подбор аккумулятора
          </button>
        </div>
        <div class="header-selection opened"
             @click="SelectionPressed = !SelectionPressed">
          <div class="header-text">
            <img class="selection" src='../assets/img/up.svg' alt="up.svg">
          </div>
        </div>
      </div>
      <div
        id="header-selection"
        class="selection_search selection_search_flex"
        :class="{ main_selection : this.$route.name === 'main' }"
      >
        <ul class="selection_search_tabs">
          <li
              class="selection_search_tab"
              :class="{ active: CurrentSelectorTab === tab}"
              v-for="(tab, index) in SelectorTabsList"
              :key="index"
              @click="CurrentSelectorTab = tab">
            {{ tab }}
          </li>
        </ul>
        <div class="selection-group selection-flex">
          <div
              class="selection-item col-5"
              v-for="(item, ind1) in SelectorsList"
              v-show="item.tab === CurrentSelectorTab"
              :key="ind1"
          >
            <input
                autocomplete="new-password"
                class="uk-input main_selector"
                :placeholder="item.title"
                :data-index="`pc-${ind1}`"
                @keyup="insertedSearchFilter($event, item.name)"
                @focus="$event.target.nextSibling.classList.add('is-active')"
                @blur="
                        $event.target.nextSibling.matches(':hover') ? '' :
                        $event.target.nextSibling.classList.remove('is-active')
                      "
                @keypress.enter="autoInsertedSearchFilter($event)"
                :data-value="item.variants && checkFilter ? insertedInputValue(item, `pc-${ind1}`)  : true"
                :disabled="!item.variants.length"
                :id="`pc-${item.name}`"
            />
            <div class="selection-item__list">
              <div v-if="variant.title || variant.properties"
                   v-for="(variant, ind2) in item.variants"
                   v-show="showBySearch(item.name, variant)"
                   :key="ind2"
                   :data-value="variant.id || variant.title"
                   @click="onClickFetchData($event, ind1, item.name)"
                   class="selection-item__list-item">{{ variant.title || variant.properties }}</div>
            </div>
          </div>
        </div>
        <div class="selection_group_button">
          <button
              class="selection_button"
              :class="{ unavaliable: !showButton }"
              @click="showButton ? applyFilterParams() : popupToggle('flex')"
          >
            Подбор аккумулятора
          </button>
        </div>
      </div>
    </div>
    <div id="header-mobile" class="header_mobile">
      <div class="header">
        <a href="/"><img src='../assets/img/FB.svg' alt="FB.svg"></a>
        <img class="menu" @click="openModalMenu()" src='../assets/img/menu.svg' alt="menu.svg">
      </div>
      <div class="header-selection"
           v-show="!SelectionPressed"
           @click="SelectionPressed = !SelectionPressed">
        <div class="header-text">
          <p class="mobile_selection">Подбор аккумулятора</p>
          <img class="selection" src='../assets/img/down.svg' alt="down.svg">
        </div>
      </div>
      <div class="header-selector__mobile"
           v-show="SelectionPressed">
        <ul class="header">
          <li
              class="selector_item"
              :class="{ active: CurrentSelectorTab === tab}"
              v-for="(tab, index) in SelectorTabsList"
              :key="index"
              @click="CurrentSelectorTab = tab">
            {{ tab }}
          </li>
        </ul>
        <div class="selection-group selection-flex">
          <div
              class="selection-item col-5"
              v-for="(item, ind1) in SelectorsList"
              v-show="item.tab === CurrentSelectorTab"
              :key="ind1"
          >
            <input
                autocomplete="new-password"
                class="uk-input main_selector"
                :placeholder="item.title"
                :data-index="`mobile-${ind1}`"
                @keyup="insertedSearchFilter($event, item.name)"
                @focus="$event.target.nextSibling.classList.add('is-active')"
                @blur="
                        $event.target.nextSibling.matches(':hover') ? '' :
                        $event.target.nextSibling.classList.remove('is-active')
                      "
                @keypress.enter="autoInsertedSearchFilter($event)"
                :data-value="item.variants && checkFilter ? insertedInputValue(item, `mobile-${ind1}`) : true"
                :disabled="!item.variants.length"
                :id="`mobile-${item.name}`"
            />
            <div class="selection-item__list">
              <div v-if="variant.title || variant.properties"
                   v-for="(variant, ind2) in item.variants"
                   v-show="showBySearch(item.name, variant)"
                   :key="ind2"
                   :data-value="variant.id || variant.title"
                   @click="onClickFetchData($event, ind1, item.name)"
                   class="selection-item__list-item"
              >
                {{ variant.title || variant.properties }}
              </div>
            </div>
          </div>
        </div>
        <button
          class="selection_button"
          :class="{ unavaliable: !showButton }"
          @click="showButton ? applyFilterParams() : popupToggle('flex')"
        >
          Подбор аккумулятора
        </button>
      </div>
      <div class="header-selection opened"
           v-show="SelectionPressed"
           @click="SelectionPressed = !SelectionPressed">
        <div class="header-text">
          <img class="selection" src='../assets/img/up.svg' alt="up.svg">
        </div>
      </div>
    </div>
    <div id="modalMenu" class="modal">
      <div class="header">
        <a href="/"><img src='../assets/img/FB.svg'></a>
        <img class='menu' @click="closeModalMenu()" src='../assets/img/cross.svg' alt="cross.svg"/>
      </div>
      <div class="header-selection"
           @click="(SelectionPressed = true)
                  && (closeModalMenu())">
        <div class="header-text">
          <p class="mobile_selection">Подбор аккумулятора</p>
          <img class="selection" src='../assets/img/down.svg' alt="down.svg">
        </div>
      </div>
      <div class="modal-menu">
        <div
          class="menu-item__mobile"
          :class="{ selected: this.$route.path === '/catalog'}"
        >
          <a class="black" @click="closeModalMenu(), openPage('/catalog')">
            <p class="mobile_menu">Каталог</p></a>
        </div>
        <div
          class="menu-item__mobile"
          :class="{ selected: this.$route.path === '/about'}"
        >
          <a class="black"@click="closeModalMenu(), openPage('/about')">
            <p class="mobile_menu">О компании</p></a>
        </div>
        <div
          class="menu-item__mobile"
          :class="{ selected: this.$route.path === '/info'}"
        >
          <a class="black"@click="closeModalMenu(), openPage('/info')">
            <p class="mobile_menu">Техническая информация</p></a>
        </div>
        <div
          class="menu-item__mobile"
          :class="{ selected: this.$route.path === '/assurance'}"
        >
          <a class="black"@click="closeModalMenu(), openPage('/assurance')">
            <p class="mobile_menu">Гарантия</p></a>
        </div>
        <div
          class="menu-item__mobile"
          :class="{ selected: this.$route.path === '/map'}"
        >
          <a class="black"@click="closeModalMenu(), openPage('/map')">
            <p class="mobile_menu">Где купить</p></a>
        </div>
        <div
          class="menu-item__mobile"
          :class="{ selected: this.$route.path === '/partners'}"
        >
          <a class="black"@click="closeModalMenu(), openPage('/partners')">
            <p class="mobile_menu">Партнерам</p></a>
        </div>
      </div>
    </div>
    <div id="popup" class="modal-popup">
      <div class="popup">
        <span class='close blue popup' @click="popupToggle('none')">&times;</span>
        <p>Ошибка: заполните все параметры</p>
      </div>
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import utils from '../utils';

export default {
  name: 'the-header',
  data() {
    return {
      checkFilter: true,
      filterParamsTmp: [],
      SelectionPressed: false,
      SelectedFilters: [],
      SelectedSearch: [],
      CurrentSelectorTab: 'По автомобилю',
      SelectorTabsList: ['По автомобилю', 'По параметрам'],
      SelectorsList: [
        {
          tab: 'По автомобилю',
          title: 'Расположение руля',
          name: 'rudders',
          ref: ['marks'],
          variants: [{
            id: 1,
            title: 'Левый руль',
          },
          {
            id: 2,
            title: 'Правый руль',
          }],
        },
        {
          tab: 'По автомобилю',
          title: 'Производитель',
          name: 'marks',
          ref: ['models'],
          target: ['rudders'],
          variants: [],
        },
        {
          tab: 'По автомобилю',
          title: 'Модель',
          name: 'models',
          ref: ['carcases', 'engines'],
          target: ['rudders'],
          variants: [],
        },
        {
          tab: 'По автомобилю',
          title: 'Кузов',
          name: 'carcases',
          ref: ['engines'],
          target: ['models', 'rudders'],
          variants: [],
        },
        {
          tab: 'По автомобилю',
          title: 'Двигатель',
          name: 'engines',
          variants: [],
        },
        {
          tab: 'По параметрам',
          title: 'Стандарт',
          name: 'standards',
          ref: ['sizes'],
          variants: [],
          static: true,
        },
        {
          tab: 'По параметрам',
          title: 'Размеры',
          name: 'sizes',
          variants: [],
        },
      ],
    };
  },
  computed: {
    showButton() {
      const { query } = this.$store.state.query ? this.$store.state : this.$route;
      if (query && Object.keys(query).length > 1) return true;
      if (this.$store.state.filters.car.length > 0 && this.CurrentSelectorTab === 'По автомобилю') return true;
      if (this.$store.state.filters.params.length > 0 && this.CurrentSelectorTab === 'По параметрам') return true;
      return false;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    openPage(link) {
      let target = link;
      let separator = '?';
      if (this.CurrentSelectorTab === 'По автомобилю') target += `${separator}type=1`;
      if (this.CurrentSelectorTab === 'По параметрам') target += `${separator}type=2`;
      const map = new Map(Object.entries(this.SelectedFilters));
      map.forEach((value, key) => {
        separator = '&';
        target += `${separator + key}=${value}`;
      });
      document.location = target;
    },
    popupToggle(toggle) {
      const popup = document.getElementById('popup');
      if (toggle === 'none') {
        popup.classList.add('disappearance');
        setTimeout(() => {
          popup.classList.remove('disappearance');
          popup.style.display = toggle;
        }, 400);
      } else if (toggle === 'flex') popup.style.display = toggle;
    },
    handleScroll() {
      const header = document.getElementById('header');
      const headerMobile = document.getElementById('header-mobile');
      const headerSelection = document.getElementById('header-selection');
      const body = document.getElementById('body');
      const logo = document.getElementById('header-logo');
      const img = document.getElementById('header-img');
      const offset = header.offsetTop;
      if (window.pageYOffset > offset) {
        logo.classList.add('small');
        img.classList.add('small');
        headerMobile.classList.add('header_scroll');
        header.classList.add('header_scroll');
        if (this.$route.name === 'main') {
          body.classList.add('body-fixed__main');
          headerSelection.classList.add('show');
        }
      } else {
        headerMobile.classList.remove('header_scroll');
        header.classList.remove('header_scroll');
        if (this.$route.name === 'main') {
          body.classList.remove('body-fixed__main');
          logo.classList.remove('small');
          img.classList.remove('small');
          headerSelection.classList.remove('show');
        }
      }
    },
    openModalMenu() {
      const modal = document.getElementById('modalMenu');
      modal.style.display = 'block';
      const body = document.getElementById('body');
      body.style.position = 'fixed';
    },
    closeModalMenu() {
      const modal = document.getElementById('modalMenu');
      modal.style.display = 'none';
      const body = document.getElementById('body');
      body.style.position = 'relative';
    },
    // Создаем ссылку с параметрами фильтров и переходим на страницу подбора
    applyFilterParams() {
      let target = '/catalog/selection';
      let separator = '?';
      if (this.CurrentSelectorTab === 'По автомобилю') target += `${separator}type=1`;
      if (this.CurrentSelectorTab === 'По параметрам') target += `${separator}type=2`;
      const map = new Map(Object.entries(this.SelectedFilters));
      map.forEach((value, key) => {
        separator = '&';
        target += `${separator + key}=${value}`;
      });
      document.location = target;
    },
    // Запускаем проверку существования фильтров, выбранных раннее
    checkFilterParams() {
      if (this.$route.query.type === '2') this.CurrentSelectorTab = 'По параметрам';
      this.autoFetchData();
    },
    // Выбирает в select значение равное ранее существующему по id
    selectedStatus(variant, item) {
      let { query } = this.$store.state;
      if (query) query = this.$store.state.query[item.name];
      else query = this.$route.query[item.name];
      if (undefined !== query) {
        if ((variant.id === +query)
            || (variant.title === query)) {
          return true;
        }
      }
      return false;
    },
    // Выбираем и вставляем в input значение равное ранее существующему по id
    insertedInputValue(item, index) {
      let { query } = this.$store.state;
      if (query) query = this.$store.state.query[item.name];
      else query = this.$route.query[item.name];
      item.variants.forEach((variant) => {
        const input = document.querySelector(`input[data-index="${index}"]`);
        if ((variant.id === +query || variant.title === query) && input) {
          input.value = variant.title || variant.properties;
        }
      });
      return true;
    },
    // Импорт функции
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
    // Заполняем выпадающие списки по раннее существуюшим параметрам по id
    async autoFetchData() {
      const { query } = this.$store.state.query ? this.$store.state : this.$route;
      if (query) {
        await Promise.all(Object.keys(query).map(async (key) => {
          await Promise.all(this.SelectorsList.map(async (item, i) => {
            if (key === item.name) {
              await this.getData(query[key], i, key);
            }
          }));
        }));
      }
      this.checkFilter = false;
    },
    // Выбираем item и получаем новые списки зависимых полей для input
    onClickFetchData(event, index, name) {
      this.choiceInput(event);
      const elem = event.target;
      const parent = elem.closest('.selection-group');
      const id = elem.dataset.value;
      this.getData(id, index, name, parent);
      this.setState(id, name);
    },
    fetchFromMain(id, name) {
      this.SelectedFilters[name] = id;
      this.setState(id, name);
    },
    setState(id, name) {
      if (this.CurrentSelectorTab === 'По автомобилю') {
        const payload = {
          type: 'car',
          data: {
            name_: name,
            id_: id,
          },
        };
        this.$store.commit('addFilter', payload);
      }
      if (this.CurrentSelectorTab === 'По параметрам') {
        const payload = {
          type: 'params',
          data: {
            name_: name,
            id_: id,
          },
        };
        this.$store.commit('addFilter', payload);
      }
    },
    // Получаем списки для выбранного параметра (SelectorsList[index]) и его зависимостей (.ref)
    async getData(id, index, name, parent = null) {
      const data = this.SelectorsList[index];
      this.SelectedFilters[name] = id;
      if (data.ref) {
        if (parent) this.clearList(data.ref, parent);
        await Promise.all(this.SelectorsList.map(async (item, i) => {
          await Promise.all(data.ref.map(async (ref) => {
            let entity = 'selection';
            if (item.tab === this.SelectorTabsList[1]) entity = 'selection/products';
            if (ref === item.name) {
              const rt = await this.setTargetLink(data.target, item.name);
              const target = `${item.name}?${data.name.slice(0, -1)}=${id}${rt}`;
              this.SelectorsList[i].variants = await this.fetchData(entity, target);
            }
          }));
        }));
      }
    },
    async setTargetLink(target, name) {
      let url = '';
      let { query } = this.$store.state || this.$route;
      if (undefined !== query) query = this.SelectedFilters;
      if (target && query) {
        await Promise.all(target.map(async (rt) => {
          if (query[rt] && name !== rt) url += `&${rt.slice(0, -1)}=${query[rt]}`;
        }));
      }
      return url;
    },
    // Меняем значение ввода input по клику на лист
    choiceInput(event) {
      const parent = event.target.closest('.selection-item');
      const input = parent.querySelector('input');
      parent.querySelector('.selection-item__list').classList.toggle('is-active');
      input.value = event.target.innerText;
    },
    // Очищаем зависимые поля input по клику на лист
    clearList(refs, parent) {
      refs.forEach((ref) => {
        this.SelectorsList.forEach((element, index) => {
          if (ref === element.name) {
            delete this.SelectedFilters[element.name];
            this.SelectorsList[index].variants = [];
            const refChild = this.SelectorsList[index].ref;
            // для живого поиска
            if (parent) {
              document.querySelector(`input[data-index="pc-${index}"]`).value = null;
              document.querySelector(`input[data-index="tablet-${index}"]`).value = null;
              document.querySelector(`input[data-index="mobile-${index}"]`).value = null;
            }
            if (refChild) this.clearList(refChild, parent);
          }
        });
      });
      this.$store.commit('clearFilters');
    },
    // Фильтруем показ листов выпадающего списка по вводу input
    showBySearch(name, value) {
      const search = this.SelectedSearch[name];
      if (!search || search.length < 2) return true;
      return (value.title && value.title.toLowerCase().includes(search.toLowerCase()))
          || (value.properties && value.properties.toLowerCase().includes(search.toLowerCase()));
    },
    // Задаем фильтр по вводу input
    insertedSearchFilter(event, name) {
      this.SelectedSearch[name] = event.target.value;
      delete this.SelectedFilters[name];
      this.$forceUpdate();
    },
    // Задаем фильтр по клику enter на input
    autoInsertedSearchFilter(event) {
      const parent = event.target.closest('.selection-item');
      const list = parent.querySelectorAll('.selection-item__list-item');
      const BreakException = {};
      try {
        list.forEach((item) => {
          const text = item.innerText.toLowerCase();
          if (text.includes(event.target.value.toLowerCase())) {
            item.click();
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    async getStaticData() {
      await Promise.all(this.SelectorsList.map(async (item, i) => {
        if (item.tab === this.SelectorTabsList[1] && item.static) {
          const target = `products/${item.name}`;
          this.SelectorsList[i].variants = await this.fetchData('selection', target);
        }
      }));
      this.checkFilterParams();
    },
  },
  mounted() {
    this.getStaticData();
    const header = document.getElementById('header');
    const headerMobile = document.getElementById('header-mobile');
    const body = document.getElementById('body');
    header.classList.add('fixed');
    headerMobile.classList.add('fixed');
    if (this.$route.name === 'main') {
      body.classList.add('body-fixed__default');
    } else {
      body.classList.add('body-fixed');
    }
    this.$root.$on('popupBox', () => {
      this.popupToggle('flex');
    });
    this.$root.$on('openPage', (link) => {
      this.openPage(link);
    });
    this.$root.$on('fetchFromMain', (payload) => {
      const id = payload[0];
      const name = payload[1];
      this.fetchFromMain(id, name);
    });
  },
};
</script>
