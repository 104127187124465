<template>
    <div
        class='main_news'
        v-if="NewsList">
        <div class="main_news_item">
            <div class='main_news_image_container'>
                <a href="#">
                    <img
                        class='main_news'
                        :src="getStaticImage('news', NewsList.filename)"
                        :alt="NewsList.filename">
                    </a>
                <div class='main_news_image_text_block'>
                    <p class='main_news_date'>{{ foramtDate }}</p>
                </div>
            </div>
            <div class='main_news_info'>
                <h3 class='main_news_title'>{{ NewsList.title }}</h3>
                <p class='main_news_text' v-html="NewsList.announc"></p>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '../utils';

export default {
  name: 'main-news',
  data() {
    return {
      NewsList: null,
    };
  },
  computed: {
    foramtDate() {
      return this.NewsList.date.split(' ')[0];
    },
  },
  methods: {
    async fetchData(action, target) {
      const { fetchData } = utils();
      return fetchData(action, target);
    },
    getStaticImage(type, name) {
      const { getStaticImage } = utils();
      return getStaticImage(type, name);
    },
  },
  async beforeMount() {
    const response = await this.fetchData('get', 'news?last=true');
    if (response) {
      this.NewsList = response;
    }
  },
};
</script>
